import React, { useState } from "react";

import { useSession } from "@app/context/Session";
import { useContactList } from "@app/queries";
import {
  B, BB, BH, BMessage, ErrorIcon,
  Header,
  LoadingPage2, SearchIcon, SubHeader, Tag
} from "@app/ui";
import { ContactItem } from "@app/ui/Contact";
import { SearchInput } from "@app/ui/Form";
import { List } from "@app/ui/List";
import { navigate } from "@reach/router";
import debounce from "lodash.debounce";

export const Contacts = ({ group }) => {
  const { current } = useSession();

  group = group == "_all" ? "" : group;

  const { isLoading, error, data } = useContactList(current.tenant.id, group);

  const [search, setSearch] = useState(null);
  const onSearch = debounce((key) => {
    setSearch(key.toLowerCase());
  }, 1000);

  if (error) {
    return (
      <BMessage
        message={"An error has occurred, can't load contacts."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );
  }

  if (isLoading) return <LoadingPage2 />;

  const fcontacts = data.list.filter(
    (c) => !search || c.email.toLowerCase().includes(search)
  );

  return (
    <>
      <Header title={"Contacts"} />
      <SubHeader>
        {group ? (
          <>
            Contacts of group
            <Tag description={`Contacts of group "${group}"`}>{group} </Tag>
          </>
        ) : (
          <>All contacts</>
        )}
      </SubHeader>
      <B>
        <BH title={""}>
          <SearchInput
            onChange={onSearch}
            placeholder={"Search by contact email"}
          />
        </BH>

        <BB>
          {fcontacts.length ? (
            <List mt={0}>
              {fcontacts.map((c) => (
                <ContactItem
                  key={c.email}
                  contact={c}
                  onClickLinkStats={(l) => {
                    const [_$, dirId, shorId] = l.refId.split(".");
                    navigate(
                      `/app/${current.tenant.id}/d/${dirId}/${shorId}/l/${l.linkId}/analytics`
                    );
                  }}
                />
              ))}
            </List>
          ) : (
            <BMessage
              message={"No contact found"}
              icon={<SearchIcon boxSize={16} mb={10} />}
            />
          )}
        </BB>
      </B>
    </>
  );
};
