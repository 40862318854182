import React, { useState } from "react";

import { useSession } from "@app/context/Session";

import { useLinkStats, useWorkspaceInfo } from "@app/queries";
import {
  ActionMenu,
  ArrowBackIcon,
  B,
  BB,
  BButton,
  BH,
  BMessage,
  Box,
  EditIcon,
  ErrorIcon,
  ExternalLinkIcon,
  Header,
  List,
  LoadingPage3,
  SearchIcon,
  SubHeader,
  Tag,
} from "@app/ui";
import { SearchInput, SortInput } from "@app/ui/Form";
import { LinkStats } from "@app/ui/Link/stats";
import { LinkVisitorItem } from "@app/ui/Link/visitors";
import { backToDoc, fullDocumentId } from "@app/utils/directory";
import debounce from "lodash.debounce";

import {
  getCustomLinkURL,
  getLinkEditURL,
  getLinkURL,
  openExternal,
} from "@app/utils";
import { navigate } from "@reach/router";

export const Stats = ({ directoryId, documentId, linkId, refId }) => {
  const {
    current: { tenant },
  } = useSession();

  const [search, setSearch] = useState(null);
  const onSearch = debounce((key) => {
    setSearch(key.toLowerCase());
  }, 500);

  const [visitorOrder, setVisitorOrder] = useState("LastVisit");

  const {
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
  } = useLinkStats(
    tenant.id,
    linkId,
    fullDocumentId(tenant.id, directoryId, documentId),
    visitorOrder
  );

  const {
    query: {
      isLoading: isLoadingWorkspaceInfo,
      error: workspaceInfoError,
      data: workspaceInfo,
    },
  } = useWorkspaceInfo(tenant.id);

  if (error || workspaceInfoError)
    return (
      <BMessage
        message={"An error has occurred, can't load link analytics."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  if (isLoading || isLoadingWorkspaceInfo) return <LoadingPage3 />;

  const {
    analytics: { info, avg, visitCount, contents },
  } = data.pages[data.pages.length - 1];

  return (
    <>
      <Header
        title={info?.refTitle ?? "Untitled link"}
        icon={<ArrowBackIcon marginRight={1} boxSize={5} />}
        _onActionClick={() => {
          backToDoc(tenant.id, directoryId, documentId);
        }}
      />

      <SubHeader>
        Statistics of link destinated to
        {info.targetLabel ? (
          <Tag description={`Link destinated to "${info.targetLabel}"`}>
            {info.targetLabel}{" "}
          </Tag>
        ) : null}
        <ActionMenu
          marginX={0}
          actions={[
            {
              label: `Edit link`,
              onClick: () => {
                navigate(
                  getLinkEditURL({
                    tenantId: tenant.id,
                    linkId: info.id,
                    dirId: directoryId,
                    docId: documentId,
                  })
                );
              },
            },
          ]}
          children
        />
      </SubHeader>

      <B>
        <BH title={"Stats"}>
          <BButton
            onClick={() => {
              openExternal(
                workspaceInfo?.profile?.subDomains?.[0]
                  ? getCustomLinkURL({
                      tenantSubDomain: workspaceInfo?.profile?.subDomains?.[0],
                      linkId: info.id,
                    })
                  : getLinkURL({
                      tenantId: tenant.id,
                      linkId: info.id,
                      type: "notionPage",
                    })
              );
            }}
            text={"Open"}
            shortText={""}
            icon={<ExternalLinkIcon boxSize={4} />}
          />
          <BButton
            onClick={() => {
              navigate(
                getLinkEditURL({
                  tenantId: tenant.id,
                  linkId: info.id,
                  dirId: directoryId,
                  docId: documentId,
                })
              );
            }}
            text={"Edit"}
            shortText={""}
            icon={<EditIcon boxSize={4} />}
          />
        </BH>
        <BB>
          <LinkStats avg={avg} info={info} visitCount={visitCount} />
        </BB>
      </B>
      <B>
        <BH title={"Visitors"}>
          <SortInput
            options={
              visitorOrder === "LastVisit"
                ? [
                    { key: "LastVisit", value: "Recent visit" },
                    { key: "LongerDuration", value: "Longer duration" },
                  ]
                : [
                    { key: "LongerDuration", value: "Longer duration" },
                    { key: "LastVisit", value: "Recent visit" },
                  ]
            }
            onChange={(e) => setVisitorOrder(e.target.value)}
          />
          <SearchInput placeholder={"Search by email"} onChange={onSearch} />
        </BH>

        <BB>
          <List>
            {data.pages.map((group, i) => {
              const fvisitors = group.analytics.visitors.filter(
                (v) =>
                  !search ||
                  (!!v.email && v.email.toLowerCase().includes(search)) ||
                  (!!v.type && v.type.toLowerCase().includes(search))
              );

              const vcount = (fvisitors || []).length;

              return vcount ? (
                <Box key={i}>
                  {fvisitors.map((v, j) => (
                    <LinkVisitorItem
                      visitor={v}
                      key={`${i + "/" + j}`}
                      contents={contents}
                      defaultExtend={i + j === 0}
                    />
                  ))}
                </Box>
              ) : null;
            })}
          </List>

          {data.pages.reduce(
            (prev, curr) =>
              prev +
              curr?.analytics?.visitors.filter(
                (v) =>
                  !search ||
                  (!!v.email && v.email.toLowerCase().includes(search)) ||
                  (!!v.type && v.type.toLowerCase().includes(search))
              ).length,
            0
          ) == 0 ? (
            <BMessage
              message={"No visitor found"}
              icon={<SearchIcon boxSize={16} mb={10} />}
            />
          ) : null}

          {hasNextPage ? (
            <BMessage
              message={""}
              icon={null}
              action={
                <BButton
                  isLoading={isFetchingNextPage}
                  text={!isFetchingNextPage ? "Load More" : ""}
                  onClick={() => fetchNextPage()}
                />
              }
            />
          ) : null}
        </BB>
      </B>

      <Box paddingBottom={16} height={"200px"} />
    </>
  );
};
