import React, { Component, useEffect } from "react";
import { navigate } from "gatsby";
// import { isLoggedIn } from "@app/utils/auth";
import { useSession } from "@app/context/Session";
import { useLocation } from "@reach/router";

export interface PrivateRouteProps {
  path: string;
  component: Component;
  tenantRequired: boolean;
}

const redirectToOB = (path, location) => {
  if (!path.includes(`/app/onboarding`)) return navigate("/app/onboarding");
};

export const PrivateRoute = ({
  component: Component,
  path,
  tenantRequired = false,
  ...rest
}) => {
  const {
    current,
    isOnboardingSession,
    startOnboarding,
    tenants,
    profile,
    isLoggedIn,
  } = useSession();

  const location = useLocation();

  const loginNeeded = !isLoggedIn();
  const isOnboarding = isOnboardingSession();
  const isOnboardingNeeded = tenantRequired && !tenants.length && !!profile;
  const selectTenantNeeded =
    tenantRequired && tenants.length && !current?.tenant?.id;
  const isOnboardingRedirectNeeded =
    isOnboarding && !path.includes(`/app/onboarding`);

  useEffect(() => {
    // console.log(
    //   "ob logic in private route effect",
    //   isOnboardingNeeded,
    //   isOnboarding,
    //   selectTenantNeeded,
    //   isOnboardingRedirectNeeded
    // );

    if (loginNeeded) {
      navigate(`/login?p=${encodeURIComponent(path)}`);
      return;
    }

    if (selectTenantNeeded && !isOnboarding) {
      navigate("/app/select-workspace");
      return;
    }

    if (isOnboardingNeeded) {
      startOnboarding().then(() => {
        redirectToOB(path, location);
      });
      return;
    }

    if (isOnboarding) {
      redirectToOB(path, location);
      return;
    }
  }, [tenantRequired, current]);

  if (
    !isLoggedIn() &&
    path.startsWith("/app") &&
    path !== `/app/auth/redirect`
  ) {
    setTimeout(() => navigate(`/login?p=${encodeURIComponent(path)}`), 0);
    return null;
  }

  if (isOnboardingRedirectNeeded || isOnboardingNeeded || selectTenantNeeded) {
    return null;
  }

  // console.log("debug Private route", {
  //   isOnboardingNeeded,
  //   isOnboarding,
  //   selectTenantNeeded,
  //   isOnboardingRedirectNeeded,
  // });

  return <Component {...rest} />;
};
