import { useSession } from "@app/context/Session";
import {
  BMessage,
  Header,
  LoadingBlock,
  SubHeader,
  Text,
} from "@app/ui/Layout";
import React, { useEffect, useState } from "react";

import { hasQueryError, redirect } from "@app/components/Auth";
import {
  useCreateNotionDirectory,
  useCreateTenant,
  useDirectoryList,
  useListNotionDatabases,
  useWorkspaceInfo,
} from "@app/queries";
import { ErrorIcon, useToast } from "@app/ui";
import {
  CreateNotionDirectoryForm,
  CreateWorkspaceForm,
  LinkNotionServiceForm,
} from "@app/ui/Form";
import { openPopupWindow } from "@app/utils";
import { RouteComponentProps, useLocation } from "@reach/router";
import { useQueryClient } from "react-query";

type step = "create_workspace" | "link_service" | "create_directory";

const stepNumber = (step: step) => {
  switch (step) {
    case "create_workspace":
      return 1;
    case "link_service":
      return 2;
    case "create_directory":
      return 3;
    default:
      return 1;
  }
};

const StepForm = ({ step, forms }) => {
  return forms[step] || null;
};

const CreateWorkspace = ({ onNext, onCancel }) => {
  const { success, failure } = useToast();
  const { switchTenant, login } = useSession();

  const createMutation = useCreateTenant(
    (data) => {
      success("Workspace successfully created");
      setTimeout(() => {
        login()
          .then(() => switchTenant(data.tenantId))
          .then(() => onNext())
          .catch((err) => {
            console.error(err);
            failure("an error has occured, please retry again");
          });
      }, 200);
    },
    (err) => {
      // if (!isErrorWithCode(err)) {
      // }
      failure("Create workspace failed, please retry again");
      console.error(err);
    }
  );

  return (
    <CreateWorkspaceForm
      // onSubmit={async (inputs: any) => {
      //   const { name, passphrase } = inputs;
      //   try {
      //     await createMutation.mutateAsync({
      //       name,
      //       passphrase,
      //     });
      //   } catch (err) {}
      // }}
      onSubmit={(setError: (name: string, value: any) => void) => {
        return async (inputs: any) => {
          const { name, passphrase, timezone } = inputs;
          try {
            await createMutation.mutateAsync({
              name,
              passphrase,
              timezone,
            });
          } catch (err) {
            const code = err?.response?.data?.error?.code;
            switch (code) {
              case "#id001":
                setError("subdomain", {
                  type: "server",
                  message: "sub is domain not allowed",
                });
                break;
              case "#id002":
                setError("subdomain", {
                  type: "server",
                  message: "sub domain is already exists",
                });
                break;
              default:
                // failure("An error has occured, please retry again");
                break;
            }
          }
        };
      }}
      onCancel={onCancel}
    />
  );
};

export const LinkNotionService = ({ redirectURL, onSkip, onCancel = null }) => {
  const { current } = useSession();

  const tenantId = current?.tenant.id;
  if (!tenantId) {
    return null;
  }

  const { failure } = useToast(10000);
  const { query: workspaceInfo, refresh } = useWorkspaceInfo(current.tenant.id);

  const [notionEnabled, setNotionEnabled] = useState(true);

  useEffect(() => {
    if (workspaceInfo.data) {
      if (
        !workspaceInfo.data?.integrations?.notion ||
        !workspaceInfo.data?.integrations?.notion?.enabled
      ) {
        setNotionEnabled(false);
        return;
      }

      if (notionEnabled) onSkip();
    }
  }, [workspaceInfo.data]);

  if (workspaceInfo.error) {
    return (
      <BMessage
        message={"Can't load workspace info, please try later."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );
  }

  const notionPopupWindow = "app-link-notion";

  const onPopupMessage = (event) => {
    if (event.origin !== process.env.GATSBY_APP_BASE_URL) {
      return;
    }
    const { data: params, source } = event;
    if (source && source?.name == notionPopupWindow) {
      //encodeURI
      const error = hasQueryError({
        params,
      });

      if (!!error) {
        failure(error);
        return;
      }

      // I'm not sure what this redirect does
      refresh().then(() => redirect({ params }));
    }
  };

  return workspaceInfo.isLoading || notionEnabled ? (
    <LoadingBlock />
  ) : (
    <LinkNotionServiceForm
      // redirectURL={redirectURL}
      onCancel={onCancel}
      onLink={() =>
        openPopupWindow(`${redirectURL}`, notionPopupWindow, onPopupMessage)
      }
    />
  );
};

export const CreateNotionDirectory = ({
  onNext,
  onInvalidStep,
  onSkip = null,
}) => {
  const { current } = useSession();
  const tenantId = current?.tenant?.id;
  if (!tenantId) {
    return null;
  }

  const { success, failure } = useToast();

  const queryClient = useQueryClient();

  const { query: workspaceInfo } = useWorkspaceInfo(tenantId);
  // if (workspaceInfo.error) return <>Can't load workspace info</>;

  const notionDatabases = useListNotionDatabases(tenantId);
  // if (notionDatabases.error)
  //   return <>Can't load available Notion databases, please retry later</>;

  const directoryList = useDirectoryList(tenantId);

  const [hasUToken, setHasUToken] = useState(null);

  useEffect(() => {
    // if (!tenantId) {
    //   navigate("/app/");
    //   return;
    // }
    if (
      workspaceInfo.data &&
      !workspaceInfo.data?.integrations?.notion?.enabled
    ) {
      onInvalidStep();
      return;
    }
    if (!notionDatabases.isLoading && notionDatabases.data) {
      setHasUToken(!!notionDatabases.data.hasUToken);
    }
  });

  const mutation = useCreateNotionDirectory(
    tenantId,
    (data) => {
      success("Directory successfully created.");
      onNext(data);
    },
    (err) => {
      console.error(err);
      failure("Failed to create directory.");
    }
  );

  if (workspaceInfo.error)
    return (
      <BMessage
        message={"Can't load workspace info."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  if (notionDatabases.error)
    return (
      <BMessage
        message={"Can't load available Notion databases, please retry later."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );
  if (directoryList.error)
    return (
      <BMessage
        message={
          "Can't load available current directories, please retry later."
        }
        icon={<ErrorIcon boxSize={24} />}
      />
    );
  if (
    workspaceInfo.isLoading ||
    notionDatabases.isLoading ||
    directoryList.isLoading
  )
    return <LoadingBlock />;

  if (hasUToken == null) return <LoadingBlock />;

  const linkedNotionDatabases = directoryList.data
    .filter((dir) => dir.type == "notionDB" && !!dir.externalId)
    .map((dir) => dir.externalId);

  const loadDatabases = (key) =>
    new Promise((resolve) => {
      setTimeout(() => {
        const options = notionDatabases.data.list
          .filter((db) => {
            return (
              !key.trim() || db.name.toLowerCase().includes(key.toLowerCase())
            );
          })
          .map((db) => ({
            label: db.name,
            value: db.id,
            disabled: linkedNotionDatabases.includes(db.id),
          }));

        if (options.length == 0) {
          queryClient.invalidateQueries(["notionDatabases", tenantId]);
        }
        resolve(options);
      }, 0);
    });

  return (
    <CreateNotionDirectoryForm
      skipUToken={hasUToken}
      onSubmit={async (inputs: any) => {
        const { databaseId, uToken, activeUser } = inputs;
        try {
          await mutation.mutateAsync({
            databaseId,
            uToken,
            activeUser,
          });
        } catch (err) {}
      }}
      loadDatabases={loadDatabases}
      onSkip={onSkip}
    />
  );
};

export interface OnboardingProps extends RouteComponentProps {
  path: string;
  tenantId: string;
  step: step;
}

export const Onboarding = ({ navigate }: OnboardingProps) => {
  const { current, tenants, endOnboarding, isOnboardingSession, switchTenant } =
    useSession();

  const [currentStep, setCurrentStep] = useState<step | null>(null);

  const paramStep = new URLSearchParams(useLocation().search).get("step") || "";

  useEffect(() => {
    const disableHistory = () => {
      window.history.go(1);
    };
    window.addEventListener("popstate", disableHistory);

    return () => {
      window.removeEventListener("popstate", disableHistory);
    };
  }, []);

  useEffect(() => {
    let initStep: step = "create_workspace";
    if (["", "create"].includes(paramStep)) {
      switchTenant(null).then(() => {
        setCurrentStep(initStep);
      });
      return;
    }

    if (["link", "dir"].includes(paramStep)) {
      switch (paramStep) {
        case "link":
          if (current) {
            initStep = "link_service";
          }
          break;
        case "dir":
          if (current) {
            initStep = "create_directory";
          }
          break;
      }
      setCurrentStep(initStep);
    }
  }, [paramStep]);

  if (!isOnboardingSession()) {
    navigate("/app/");
    return null;
  }

  const currentStepPos = stepNumber(currentStep);

  return (
    <>
      <Header
        title={currentStepPos != 1 ? current.tenant.name : "Create a workspace"}
      />
      <SubHeader justify={"flex-end"}>
        <Text
          fontSize={"md"}
          fontWeight={"semibold"}
          mr={2}
        >{`step ${currentStepPos} of 3`}</Text>
      </SubHeader>
      <StepForm
        step={currentStep}
        forms={{
          create_workspace: (
            <CreateWorkspace
              onNext={(data) => {
                navigate("/app/onboarding?step=link", { replace: true });
              }}
              onCancel={
                tenants?.length
                  ? () => {
                      switchTenant("default")
                        .then(() => endOnboarding())
                        .then(() => navigate("/app/"));
                    }
                  : null
              }
            />
          ),

          link_service: (
            <LinkNotionService
              redirectURL={`${process.env.GATSBY_API_URL}/auth/notion/authorize?tid=${current?.tenant?.id}&p=onboarding?step=dir`}
              onSkip={() => {
                navigate("/app/onboarding?step=dir", { replace: true });
              }}
              onCancel={() => {
                endOnboarding().then(() =>
                  navigate(`/app/${current.tenant.id}/`, {
                    replace: true,
                  })
                );
              }}
            />
          ),

          create_directory: (
            <CreateNotionDirectory
              onNext={(data) => {
                endOnboarding().then(() =>
                  navigate(`/app/${current.tenant.id}/d/${data.id}`, {
                    replace: true,
                  })
                );
              }}
              onInvalidStep={() => {
                navigate("/app/onboarding?step=link", { replace: true });
              }}
              onSkip={() => {
                endOnboarding().then(() =>
                  navigate(`/app/${current.tenant.id}/`, {
                    replace: true,
                  })
                );
              }}
            />
          ),
        }}
      />
    </>
  );
};
