import React from "react";

import {
  CircularProgress, Stat, StatLabel,
  StatNumber,
  StatNumberProps, StatProps, Tooltip
} from "@chakra-ui/react";

import { Text } from "@app/ui/Text";

import { useSmallScreen } from "@app/ui/hooks";
import { describeDuration, formatDateTime } from "@app/utils";

export { Stat, StatLabel, StatNumber, Tooltip, CircularProgress };

export const InlineStat = ({ children, my = 0.5, ...rest }: StatProps) => {
  return (
    <Stat
      my={my}
      display={"flex"}
      align={"center"}
      sx={{
        "& dl": {
          display: "flex",
          alignItems: "center",
        },
      }}
      {...rest}
    >
      {children}
    </Stat>
  );
};

export const StatIconLabel = ({ icon, label, shortLabel = null }) => {
  const { isSmallScreen } = useSmallScreen();

  label = isSmallScreen ? shortLabel : label;

  return (
    <StatLabel display={"flex"} alignItems={"center"}>
      {icon}
      <Text fontWeight={"normal"}>{label ? `${label}:` : null}</Text>
    </StatLabel>
  );
};
export interface StatTimeDurationProps extends StatNumberProps {
  duration: number;
}

export const StatTimeDuration = ({
  duration,
  fontSize = "sm",
  ...rest
}: StatTimeDurationProps) => {
  const { isSmallScreen } = useSmallScreen();
  return (
    <StatNumber fontSize={fontSize} {...rest}>
      {/* {isSmallScreen ? formatDuration(duration) : describeDuration(duration)} */}
      {describeDuration(duration)}
    </StatNumber>
  );
};

export interface StatTimeProps extends StatNumberProps {
  time: number;
}

export const StatTime = ({ time, fontSize = "sm", ...rest }: StatTimeProps) => {
  return (
    <StatNumber fontSize={fontSize} {...rest}>
      {formatDateTime(time)}
    </StatNumber>
  );
};

export interface StatProgressProps extends StatNumberProps {
  progress: number;
  progressColor?: string;
}

export const StatProgress = ({
  progress,
  progressColor = "green.400",
  fontSize = "sm",
  ...rest
}: StatProgressProps) => {
  return (
    <StatNumber
      fontSize={fontSize}
      display={"flex"}
      alignItems={"center"}
      {...rest}
    >
      <Text mr={1}>{`${progress}%`}</Text>
      <CircularProgress
        value={progress}
        size={"17px"}
        color={progressColor}
        thickness="15px"
      />
    </StatNumber>
  );
};
