import React from "react";

import { useSession } from "@app/context/Session";
import { useDashboard, useWorkspaceInfo } from "@app/queries";
import {
  B,
  BMessage,
  ErrorIcon,
  Header,
  LoadingBlock,
  SubHeader,
} from "@app/ui";
import {
  RecentActivities,
  RecentVisitStats,
} from "@app/ui/Workspace/dashboard";
import { displayTimezone } from "@app/utils";
import { parseDocId } from "@app/utils/directory";
import { RouteComponentProps } from "@reach/router";

interface HomeProps extends RouteComponentProps {}

export const Home = ({ navigate }: HomeProps) => {
  const { current } = useSession();

  const { isLoading, error, data } = useDashboard(current.tenant.id);

  const {
    query: {
      isLoading: isLoadingWorkspaceInfo,
      error: workspaceInfoError,
      data: workspaceInfo,
    },
  } = useWorkspaceInfo(current.tenant.id);

  if (error || workspaceInfoError)
    return (
      <BMessage
        message={"An error has occurred, can't load the home page."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  if (isLoading || isLoadingWorkspaceInfo) return <LoadingBlock />;

  const { dashboard, contacts } = data;

  const {
    ActiveLinks,
    ConfiguredLinks,
    RecentVisitors,
    DailyVisits,
    RecentVisitCount,
    MonthlyVisitCount,
    DailyVisitCount,
  } = dashboard;

  return (
    <>
      <Header title={`${current.tenant.name}`} />
      <SubHeader>
        {"Recent activities and statistics"}{" "}
        {" [" +
          (workspaceInfo?.profile?.timezone
            ? displayTimezone(workspaceInfo?.profile?.timezone)
            : "") +
          "]"}
      </SubHeader>

      <B>
        <RecentVisitStats
          timezone={workspaceInfo?.profile?.timezone}
          title={"Monthly Visit Stats"}
          dailyVisitCount={DailyVisitCount}
          recentVisitCount={RecentVisitCount}
          dailyVisits={DailyVisits}
          monthlyVisitCount={MonthlyVisitCount}
        />
      </B>

      <B>
        <RecentActivities
          contacts={contacts}
          recentVisitors={RecentVisitors}
          activeLinks={ActiveLinks}
          configuredLinks={ConfiguredLinks}
          onLinkEdit={({ id, refId, refType }) => {
            const { dirId, docId } = parseDocId(refId);
            // navigate(
            //   `/app/${current.tenant.id}/d/${dirId}/${docId}/l/${id}/edit`
            // );

            return `/app/${current.tenant.id}/d/${dirId}/${docId}/l/${id}/edit`;
          }}
          onLinkStats={({ id, refId, refType }) => {
            const { dirId, docId } = parseDocId(refId);
            // navigate(
            //   `/app/${current.tenant.id}/d/${dirId}/${docId}/l/${id}/analytics`
            // );

            return `/app/${current.tenant.id}/d/${dirId}/${docId}/l/${id}/analytics`;
          }}
        />
      </B>

      <B>
        {/* <Spacer h={10} /> */}
        <div style={{ visibility: "hidden", marginTop: "30px" }}>.</div>
      </B>
    </>
  );
};
