import { Avatar } from "@app/ui/Avatar";
import { MiniButton } from "@app/ui/Button";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  NotionPageIcon,
  StatsIcon,
} from "@app/ui/Icon";
import { BMessage, Box, HBox, Text, VBox } from "@app/ui/Layout";
import { ListItem } from "@app/ui/List";
import { Tag, Time } from "@app/ui/Text";
import React, { useState } from "react";

export const ContactItem = ({ contact, onClickLinkStats }) => {
  const [extend, setExtend] = useState(false);

  const groups = {};
  contact.links.forEach((l, i) => {
    groups[l.targetCode] = {
      code: l.targetCode,
      label: l.targetLabel,
    };
  });

  return (
    <ListItem
      key={"a"}
      paddingY={2}
      paddingX={2}
      onClick={() => setExtend(!extend)}
    >
      <VBox width={"100%"}>
        <HBox align={"center"}>
          <Avatar
            size={"xs"}
            name={contact.email}
            src={null}
            title={contact.email}
            borderRadius={4}
            mr={2}
          />
          <VBox>
            <Text mb={1}>{contact.email}</Text>
            <HBox>
              {contact.groups.map((g, i) =>
                groups[g] ? (
                  <Tag
                    key={i}
                    size={"sm"}
                    mr={2}
                    description={`Contact is added to "${groups[g]?.label}" group`}
                  >
                    {groups[g]?.label}{" "}
                  </Tag>
                ) : null
              )}
            </HBox>
          </VBox>
          <MiniButton
            aria-label={`display ${contact.email}'s visited links`}
            icon={
              extend ? (
                <ChevronUpIcon boxSize={5} />
              ) : (
                <ChevronDownIcon boxSize={5} />
              )
            }
            mode={"ghost"}
            // size={"md"}
          />
        </HBox>
        {extend && contact.links.length ? (
          <VBox
            p={4}
            pt={4}
            pl={{
              base: 3,
              md: 8,
            }}
            pr={{
              base: 3,
              md: 8,
            }}
            overflowY={"auto"}
          >
            {contact?.timezones ? (
              <>
                <HBox p={1} mb={3}>
                  <Text
                    size={"xs"}
                    textTransform={"uppercase"}
                    fontWeight={"light"}
                    mr={1}
                  >
                    Timezone:
                  </Text>
                  {contact?.timezones.map((tz) => (
                    <Tag
                      ml={1}
                      size={"sm"}
                      description={`seen from "${tz}" timezone`}
                      variant="subtle"
                      colorScheme={"green"}
                    >
                      {tz}
                    </Tag>
                  ))}
                </HBox>
              </>
            ) : null}
            <Text
              ml={1}
              mb={1}
              size={"sm"}
              textTransform={"uppercase"}
              fontWeight={"light"}
            >
              Visited links:
            </Text>
            <Box maxH={"200px"} overflowY={"auto"}>
              {contact.links.length ? (
                contact.links.map((l) => (
                  <LinkItem link={l} onClickLinkStats={onClickLinkStats} />
                ))
              ) : (
                <BMessage message={"Visited links are not found"} icon={null} />
              )}
            </Box>
          </VBox>
        ) : null}
      </VBox>
    </ListItem>
  );
};

const LinkItem = ({ link, onClickLinkStats }) => {
  return (
    <ListItem
      key={"a"}
      onClick={() => {}}
      paddingY={1}
      paddingX={1}
      variant="solid"
    >
      <VBox>
        <HBox align={"center"}>
          <NotionPageIcon boxSize={4} />
          <Text ml={2} mb={1} fontWeight={"normal"}>
            {link.refTitle}
          </Text>
        </HBox>
        <HBox align={"center"}>
          <Time at={link.lastVisitAt} label={"Last visit"} />
          <Tag
            ml={1}
            size={"sm"}
            maxW={"50%"}
            description={`Link destinated to "${link.targetLabel}"`}
          >
            {link.targetLabel}
          </Tag>
        </HBox>
      </VBox>

      <MiniButton
        aria-label={`visit link of ${link.refTitle} destinated to ${link.targetLabel}`}
        onClick={() => onClickLinkStats(link)}
        icon={<StatsIcon boxSize={3} />}
        // size={"xs"}
        mode="ghost"
      />
    </ListItem>
  );
};
