import * as React from "react";

import App from "@app/components/App";
import { PageProps } from "gatsby";
import { isBrowser } from "@app/utils";
import { BMessage, AppLogoIcon, AppLogoIconV2, LoadingLayout } from "@app/ui";

export default function AppPage(props: PageProps) {
  return isBrowser() ? (
    <App location={props.location} />
  ) : (
    <BMessage
      message={"loading app..."}
      icon={
        <>
          <AppLogoIcon boxSize={28} />
        </>
      }
    />
  );
}
