import React, { useRef, useState } from "react";

import { Text, VisitCounter, VisitDisabled } from "@app/ui";
import {
  CopyIcon,
  ExternalLinkIcon,
  LockIcon,
  PointsIcon,
  SettingsIcon,
  StatsIcon,
  UnlockIcon,
} from "@app/ui/Icon";
import { HBox, VBox } from "@app/ui/Layout";
import { ListItem } from "@app/ui/List";

import { MiniButton } from "@app/ui/Button";
import { Dialog } from "@app/ui/Dialog";
import {
  ActionMenu,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@app/ui/Menu";
import { Tag, Time, Tooltip } from "@app/ui/Text";
import { useToast } from "@app/ui/Toast";
import { authLevel, isBrowser } from "@app/utils";
import {
  Box,
  IconButton,
  useClipboard,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";

type link = {
  id: string;
  type: string;
  target: string;
  status: string;
  createdAt: number;

  visitCount: number;

  authLevel: string;
};

interface LinkItemProps {
  link: link;
  tenantId?: string;
  url?: string;
  onClickSettings?: () => void;
  onHandleEnbalement?: ({ linkId, action }) => void;
  onClickStats?: () => void;
}

export const LinkItemMenu = () => {
  return (
    <Menu closeOnSelect={true} isLazy={true}>
      <MenuButton aria-label="Options" variant="unstyled">
        <SettingsIcon boxSize={3} marginLeft={"auto"} />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => {}}>Edit link</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => {}}>Delete link</MenuItem>
      </MenuList>
    </Menu>
  );
};

export const LinkAuthLevel = ({ link }) => {
  const isPublic = ["Public", ""].includes(link.authLevel);
  return (
    <Box
      as={Tag}
      variant={!isPublic ? "solid" : "outline"}
      pb={1}
      size={"sm"}
      description={authLevel(link.authLevel)}
    >
      {isPublic ? <UnlockIcon boxSize={3} /> : <LockIcon boxSize={3} />}
    </Box>
  );
};

export const LinkItem = (props: LinkItemProps) => {
  const {
    link,
    tenantId,
    url,
    onClickSettings,
    onClickStats,
    onHandleEnbalement,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogRef = useRef();
  let dialogParams: {
    header: string;
    body: string;
    buttonColor: string;
    action: string;
  };

  switch (link.status) {
    case "enabled":
      dialogParams = {
        header: "Disable link",
        body: "Are you sure? current link viewers can't access link anymore.",
        buttonColor: "red",
        action: "disable",
      };
      break;

    case "disabled":
      dialogParams = {
        header: "Enable link",
        body: "Your link will be accessible again.",
        buttonColor: "green",
        action: "enable",
      };
    default:
      break;
  }

  const dialog = dialogParams?.action ? (
    <Dialog
      isOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      cancelRef={dialogRef}
      header={dialogParams.header}
      body={dialogParams.body}
      action={{
        name: dialogParams.action,
        color: dialogParams.buttonColor,
        callback: () =>
          onHandleEnbalement({ linkId: link.id, action: dialogParams.action }),
      }}
    />
  ) : null;

  const active = link.status == "enabled";
  return (
    <ListItem key={link.id} paddingY={2} paddingX={1} active={active}>
      {dialog}
      <VBox marginRight={"auto"} marginLeft={1}>
        <HBox marginBottom={2}>
          <LinkURL url={url} />
        </HBox>
        <HBox align={"center"} overflowX={"auto"}>
          <Time at={link.createdAt} label={"Created"} />

          {link.target ? (
            <Tag
              marginRight={2}
              size={"sm"}
              description={`Link destinated to "${link.target}"`}
            >
              {link.target}
            </Tag>
          ) : null}

          <LinkAuthLevel link={link} />
        </HBox>
      </VBox>
      <VBox marginLeft={1} align={"center"}>
        {active ? (
          <VisitCounter
            count={link.visitCount || "?"}
            label={
              link.visitCount
                ? "link visits count"
                : "link may have few visits in case of a new one"
            }
          />
        ) : (
          <VisitDisabled
            label={"link disabled"}
            count={link.visitCount || "?"}
          />
        )}
        <HBox>
          <ActionMenu
            icon={
              <PointsIcon ml={0} mt={0} boxSize={6} viewBox={"-2 -2 24 24"} />
            }
            actions={[
              {
                label: "Edit link",
                onClick: onClickSettings,
              },
              {
                label:
                  link.status == "disabled" ? "Enable Link" : "Disable link",
                onClick: () => {
                  setDialogOpen(true);
                },
              },
            ]}
            buttonSize={"sm"}
            mode={"ghost"}
            children
          />
          <MiniButton
            aria-label={"show more link information"}
            onClick={onClickStats}
            icon={<StatsIcon boxSize={4} />}
            mode={"ghost"}
          />
        </HBox>
      </VBox>
    </ListItem>
  );
};

interface LinkURLProps {
  url: string;
}

export const LinkURL = ({ url = null }: LinkURLProps) => {
  const chakraTheme = useTheme();
  const { boxColor, boxFontColor, tootlipColor, tootlipFontColor } =
    useColorModeValue(
      {
        boxColor: chakraTheme.colors.blackAlpha[50],
        boxFontColor: chakraTheme.colors.blackAlpha[600],
        tootlipColor: chakraTheme.colors.whiteAlpha[600],
        tootlipFontColor: chakraTheme.colors.gray[900],
      },
      {
        boxColor: chakraTheme.colors.blackAlpha[500],
        boxFontColor: chakraTheme.colors.gray[500],
        tootlipColor: chakraTheme.colors.blackAlpha[500],
        tootlipFontColor: chakraTheme.colors.gray[300],
      }
    );

  const { hasCopied, onCopy } = useClipboard(url);

  const { success } = useToast();

  const onCustomCopy = () => {
    success("Link copied to clipboard");
    onCopy();
  };

  return (
    <HBox
      marginLeft={0}
      align={"center"}
      // overflowX={"scroll"}
      overflowX={"auto"}
      borderRadius={4}
      borderColor={boxColor}
      bg={boxColor}
      color={boxFontColor}
      fontFamily={"mono"}
    >
      <Tooltip
        label={
          <HBox align={"center"}>
            <ExternalLinkIcon mr={2} /> double click to open link
          </HBox>
        }
        placement={"top-end"}
        backgroundColor={tootlipColor}
        color={tootlipFontColor}
      >
        <Text
          fontSize={"xs"}
          fontWeight={"medium"}
          padding={1}
          onDoubleClick={() =>
            isBrowser() && window.open(url, "_blank").focus()
          }
          onClick={onCustomCopy}
          wordBreak={"break-all"}
          userSelect={"none"}
          cursor={"pointer"}
        >
          {url?.replace("https://", "")?.replace("http://", "")}
        </Text>
      </Tooltip>

      <IconButton
        aria-label={"copy link"}
        onClick={onCustomCopy}
        variant="unstyled"
        m={0.5}
        size={"xs"}
        borderRadius={4}
        paddingBottom={1}
        icon={hasCopied ? <CopyIcon color={"green.200"} /> : <CopyIcon />}
      ></IconButton>
    </HBox>
  );
};
