import React from "react";

import { useSession } from "@app/context/Session";
import { RouteComponentProps, useLocation } from "@reach/router";
import {
  CreateNotionDirectory,
  LinkNotionService,
} from "@app/components/Onboarding";
import { Header, SubHeader } from "@app/ui";

interface CreateDirectoryProps extends RouteComponentProps {}

export const CreateDirectory = ({ navigate }: CreateDirectoryProps) => {
  const { current } = useSession();

  const paramStep = new URLSearchParams(useLocation().search).get("step") || "";

  switch (paramStep) {
    case "":
    case "dir":
      return (
        <>
          <Header title={current.tenant.name} />
          <SubHeader />
          <CreateNotionDirectory
            onNext={(data) => {
              navigate(`/app/${current.tenant.id}/d/${data.id}`, {
                replace: true,
              });
            }}
            onInvalidStep={() => {
              navigate(`/app/${current.tenant.id}/d/create?step=link`, {
                replace: true,
              });
            }}
          />
        </>
      );
    case "link":
      return (
        <>
          <Header title={current.tenant.name} />
          <SubHeader />
          <LinkNotionService
            redirectURL={`${process.env.GATSBY_API_URL}/auth/notion/authorize?tid=${current.tenant.id}&p=d/create?step=dir`}
            onSkip={() => {
              navigate(`/app/${current.tenant.id}/d/create?step=dir`, {
                replace: true,
              });
            }}
          />
        </>
      );
    default:
      return <>Malformed create directory form URL </>;
  }
};
