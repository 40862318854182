import React from "react";
import {
  Button,
  Header,
  LoadingBlock,
  SubHeader,
  useToast,
  VBox,
} from "@app/ui";
import { AddMember, MemberGroup } from "@app/ui/Member";
import { useSession } from "@app/context/Session";

import {
  useChangeRole,
  useDeleteRole,
  useInviteMembers,
  useRoles,
} from "@app/queries";

export const Members = () => {
  const {
    current: { tenant },
  } = useSession();

  const { isLoading, error, data } = useRoles(tenant.id);

  const { success, failure } = useToast();

  const changeMutation = useChangeRole(
    tenant.id,
    () => success("User role updated"),
    () => failure("Update role failed, an error has occured")
  );

  const deleteMutation = useDeleteRole(
    tenant.id,
    () => success("User deleted"),
    () => failure("Delete role failed, an error has occured")
  );

  const inviteMutation = useInviteMembers(
    tenant.id,
    () => success("invitation is successfully sent"),
    () => failure("invitation request failed, an error has occured")
  );

  if (error) return "An error has occurred: " + error.message;

  return isLoading ? (
    <LoadingBlock />
  ) : (
    <>
      <Header title={`${tenant.name}'s members`} />
      <SubHeader>
        <AddMember
          existingMembers={data.map((u) => u.email.toLowerCase())}
          onAddMember={(memberEmail: string) => {
            inviteMutation.mutate({
              tenantId: tenant.id,
              contacts: [{ email: memberEmail }],
            });
          }}
        />
      </SubHeader>
      <MemberGroup
        members={data.map((u) => {
          return {
            ...u,
            name: u.fullname || u.username || u.email,
            // picture: u.avatarUrl,
          };
        })}
        onRoleChange={(id: string, oldRole: string, newRole: string) => {
          changeMutation.mutate({
            userId: id,
            role: newRole,
            tenantId: tenant.id,
          });
        }}
        onDeleteMember={(id: string) => {
          deleteMutation.mutate({
            userId: id,
            tenantId: tenant.id,
          });
        }}
      />
    </>
  );
};
