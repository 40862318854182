import React from "react";

import {
  ArrowRightIcon, AttachmentIcon,
  DocumentIcon, DownloadIcon,
  ExternalLinkIcon, HBox, LinkIcon,
  StatsIcon, Text, TimeIcon, VBox, VideoIcon,
  ViewIcon
} from "@app/ui";
import { DefaultBarChart, DefaultTooltip } from "@app/ui/Chart";
import { prepareBarChartdata } from "@app/ui/Link/visitors";
import {
  InlineStat, Stat, StatIconLabel, StatProgress, StatTime, StatTimeDuration, Tooltip
} from "@app/ui/Stats";
import { describeDuration, formatDuration } from "@app/utils";

export type Asset = {
  id: string;
  title: string;
  type: "embedded_pdf" | "link" | "file" | "video" | "detail";
  properties?: any;
};

export interface AssetProps {
  asset: Asset;
}

export const AssetFactory = ({ asset }: AssetProps) => {
  switch (asset.type) {
    case "link":
      return <Link asset={asset} />;
    case "file":
      return <File asset={asset} />;
    case "embedded_pdf":
      return <Document asset={asset} />;
    case "video":
      return <Video asset={asset} />;
    case "detail":
      return <Detail asset={asset} />;
    default:
      return null;
  }
};

const AssetTitle = ({ icon, AssetTitle }) => {
  return (
    // <HBox align={"center"} mb={1} color={"blackAlpha.700"}>
    <HBox align={"center"} mb={0}>
      {icon}
      <Text fontWeight={"medium"} fontSize={"sm"}>
        {AssetTitle}
      </Text>
    </HBox>
  );
};

const AssetBody = ({ children }) => {
  return (
    <HBox pl={2} color={"gray.600"}>
      {children}
    </HBox>
  );
};
export const Link = ({ asset }: AssetProps) => {
  return (
    <VBox _hover={{ opacity: 1 }} opacity={0.8} p={1} mb={2}>
      <AssetTitle
        icon={<LinkIcon boxSize={4} mr={1} color={"cyan.400"} />}
        AssetTitle={asset.title}
      />
      <AssetBody>
        <InlineStat>
          <StatIconLabel
            icon={<ExternalLinkIcon boxSize={3} mr={1} />}
            label="Opened"
          />
          <StatTime ml={1} time={asset.properties?.lastVisitAt} />
        </InlineStat>
      </AssetBody>
    </VBox>
  );
};

export const File = ({ asset }: AssetProps) => {
  return (
    <VBox _hover={{ opacity: 1 }} opacity={0.8} p={1} mb={2}>
      <AssetTitle
        icon={<AttachmentIcon boxSize={4} mr={1} color={"gray.500"} />}
        AssetTitle={asset.title}
      />
      <AssetBody>
        <InlineStat>
          <StatIconLabel
            icon={<DownloadIcon boxSize={4} mr={1} />}
            label="Downloaded"
          />
          <StatTime ml={1} time={asset.properties?.lastDownloadAt} />
        </InlineStat>
      </AssetBody>
    </VBox>
  );
};

export const Detail = ({ asset }: AssetProps) => {
  return (
    <VBox _hover={{ opacity: 1 }} opacity={0.8} p={1} mb={2}>
      <AssetTitle
        icon={
          <ArrowRightIcon
            boxSize={3.5}
            mx={1}
            mr={1.5}
            color={"blackAlpha.700"}
            bg={"whiteAlpha.900"}
            p={0.5}
            borderRadius={3}
          />
        }
        AssetTitle={asset.title}
      />
      <AssetBody>
        <InlineStat>
          <StatIconLabel
            icon={<ViewIcon boxSize={3} mr={1} />}
            label="Opened"
          />
          <StatTime ml={1} time={asset.properties?.lastVisitAt} />
        </InlineStat>
      </AssetBody>
    </VBox>
  );
};

export const Video = ({ asset }: AssetProps) => {
  const spentDuration = asset.properties?.spentDuration || 0;
  const progress = asset.properties?.duration
    ? Math.ceil((spentDuration / asset.properties?.duration) * 100)
    : 0;
  return (
    <VBox _hover={{ opacity: 1 }} opacity={0.8} p={1} mb={2}>
      <AssetTitle
        icon={<VideoIcon boxSize={5} mr={1} color={"red.400"} />}
        AssetTitle={asset.title}
      />
      <AssetBody>
        <InlineStat>
          <StatIconLabel
            icon={<TimeIcon boxSize={3} mr={1} />}
            label="Time spent"
          />
          <StatTimeDuration ml={1} duration={spentDuration} />
        </InlineStat>
        <InlineStat ml={"auto"}>
          <Tooltip
            placement={"top"}
            label={`total duration: ${asset.properties?.duration || "?"}`}
          >
            <StatIconLabel
              icon={<ViewIcon boxSize={3} mr={1} />}
              label="Viewed"
            />
          </Tooltip>
          <StatProgress ml={1} progress={progress} />
        </InlineStat>
      </AssetBody>
    </VBox>
  );
};

export const Document = ({ asset }: AssetProps) => {
  //   const avg = {
  //     visitDurationTotal: 245 + 60 * 60,
  //     visitPageDurationTotal: {
  //       "#1": 10,
  //       "#2": 105 + 90 * 40,
  //       "#5": 35,
  //       "#6": 350 + 60 * 30,
  //       "#22": 235 + 60 * 60 * 2,
  //     },
  //   };

  const chartData = prepareBarChartdata(
    asset.properties?.pageVisitDuration || {}
  );

  const spendDuration = asset.properties?.spentDuration || 0;

  if (!spendDuration) {
    return null;
  }

  const progress = asset.properties?.pageCount
    ? (Object.keys(asset.properties?.pageVisitDuration ?? {}).length /
        asset.properties?.pageCount) *
      100
    : 0;

  return (
    <VBox _hover={{ opacity: 1 }} opacity={0.8} p={1} mb={2}>
      <AssetTitle
        icon={<DocumentIcon boxSize={5} mr={1} color={"yellow.400"} />}
        AssetTitle={asset.title}
      />
      <AssetBody>
        <InlineStat>
          <StatIconLabel
            icon={<TimeIcon boxSize={3} mr={1} />}
            label="Time spent"
          />
          <StatTimeDuration ml={1} duration={spendDuration} />
        </InlineStat>
        <InlineStat>
          <StatIconLabel
            icon={<ViewIcon boxSize={3} mr={1} />}
            label="Viewed"
          />
          <StatProgress progress={progress} ml={1} />
        </InlineStat>
      </AssetBody>
      {chartData.length ? (
        <AssetBody>
          <Stat overflowX={"auto"} my={0.5}>
            <StatIconLabel
              icon={<StatsIcon boxSize={3} mr={1} />}
              label="Time per page"
            />

            <DefaultBarChart
              data={chartData}
              dataKey={{ keyX: "name", keyY: "duration" }}
              tickYFormatter={(label) => formatDuration(parseInt(label))}
              height={200}
              showLegend={false}
              tooltip={
                <DefaultTooltip
                  mapper={(payload, label) => ({
                    keyX: null,
                    x: label,
                    keyY: payload?.name,
                    y: describeDuration(parseInt(payload?.value)),
                  })}
                />
              }
            />
          </Stat>
        </AssetBody>
      ) : null}
    </VBox>
  );
};
