import React, { ReactNode } from "react";
import {
  Body,
  Logo,
  Main,
  Nav,
  Profile,
  SettingsIcon,
  SideMenu,
  SideMenuGroup,
  SideMenuItem,
  SideNav,
  ViewPort,
  Workspace,
  Drawer,
  Box,
  Heading,
  HBox,
  SideFooter,
  HomeIcon,
} from "@app/ui";
import { useSession } from "@app/context/Session";

import { navigate, useLocation } from "@reach/router";
import { DirectoryList, ContactGroupList } from "@app/components/Menu";
import { useSmallScreen } from "@app/ui/hooks";
import { Spacer } from "@chakra-ui/react";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const {
    profile,
    current,
    isOnboardingSession,
    tenants,
    logout,
    switchTenant,
    startOnboarding,
  } = useSession();

  const location = useLocation();

  const { isSmallScreen } = useSmallScreen();

  const sideBarContent = (
    <>
      <Logo />
      {(!!current || tenants.length == 0) && !isOnboardingSession() ? (
        <>
          <Workspace
            workspace={current}
            workspaces={tenants}
            onSwitchWorkspace={(id) => {
              // switchTenant(id);
              navigate(`/app/${id}/`);
            }}
            onCreateWorkspace={() => {
              startOnboarding().then(() =>
                navigate("/app/onboarding?step=create")
              );
            }}
          />
          <SideMenu>
            {current && (
              <>
                <SideMenuGroup key="workspaceSettings">
                  <SideMenuItem
                    title={"Home"}
                    to={`/app/${current.tenant.id}/`}
                    hasTooltip={false}
                    icon={<HomeIcon boxSize={4} />}
                    current={location.pathname}
                  />
                  <SideMenuItem
                    title={"Settings"}
                    to={`/app/${current.tenant.id}/settings`}
                    hasTooltip={false}
                    icon={<SettingsIcon />}
                    current={location.pathname}
                  />
                  {/* <SideMenuItem
                    title={"Members"}
                    to={`app/${current.tenant.id}/members`}
                    hasTooltip={false}
                    icon={<SettingsIcon />}
                    current={location.pathname}
                  /> */}
                </SideMenuGroup>

                <DirectoryList />
                <ContactGroupList />
              </>
            )}
          </SideMenu>
          {isSmallScreen ? null : (
            <SideFooter>
              <Profile
                user={profile}
                onClickLogout={() => logout()}
                mode={"extend"}
              />
            </SideFooter>
          )}
        </>
      ) : isSmallScreen ? null : (
        <SideFooter>
          <Profile
            user={profile}
            onClickLogout={() => logout()}
            mode={"extend"}
          />
        </SideFooter>
      )}
    </>
  );

  return (
    <ViewPort>
      {isSmallScreen ? null : <SideNav>{sideBarContent}</SideNav>}
      <Body>
        {isSmallScreen ? (
          <Nav>
            <Drawer>{sideBarContent}</Drawer>
            <Spacer />
            <Profile user={profile} onClickLogout={() => logout()} />
          </Nav>
        ) : null}
        <Main>
          <>{children}</>
        </Main>
        {/* <Box mt={20}>app footer</Box> */}
      </Body>
    </ViewPort>
  );
};
