import React from "react";

import { hasQueryError, redirect } from "@app/components/Auth";
import { useSession } from "@app/context/Session";
import { useEditNotionServiceSettings, useWorkspaceInfo } from "@app/queries";
import {
  B,
  BMessage,
  ErrorIcon,
  Header,
  LoadingBlock,
  NotionIcon,
  SubHeader,
  Tag,
  useToast,
} from "@app/ui";
import { EditNotionServiceForm, LinkNotionServiceForm } from "@app/ui/Form";
import { SettingsFormList, SettingsTabs } from "@app/ui/Workspace";
import { openPopupWindow } from "@app/utils";
import { RouteComponentProps } from "@reach/router";

interface SettingsProps extends RouteComponentProps {}

export const Settings = ({ location }: SettingsProps) => {
  const { current } = useSession();

  const params = new URLSearchParams(location.search);

  const [defaultTab, defaultForm] = params.has("tab")
    ? params.get("tab").split(".")
    : [null, null];

  return (
    <>
      <Header title={`Settings`} />
      <SubHeader>
        Adjust workspace settings and integrations
        {/* <Text as={"span"} ml={1} fontWeight={"semibold"}>
          {current.tenant.name}
        </Text> */}
      </SubHeader>
      <B>
        <SettingsTabs
          defaultTab={defaultTab}
          data={[
            {
              label: "Integrations",
              content: <WorkspaceSettings defaultForm={defaultForm} />,
            },
            // {
            //   label: "Members",
            //   content: <p>Comming soon</p>,
            // },
          ]}
        />
      </B>
    </>
  );
};

const WorkspaceSettings = ({ defaultForm }) => {
  const { current } = useSession();

  const {
    query: { isLoading, error, data: info },
    refresh,
  } = useWorkspaceInfo(current.tenant.id);

  const { success, failure } = useToast();

  const notionMutation = useEditNotionServiceSettings(
    current.tenant.id,
    (data) => {
      success("Notion settings successfully updated");
    },
    (err) => {
      console.error(err);
      failure("Failed to update Notion settings, try to use a valid token");
    }
  );
  if (error)
    return (
      <BMessage
        message={"An error has occurred, can't load workspace settings."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  if (isLoading) return <LoadingBlock />;

  const notionEnabled = !!info?.integrations?.notion?.enabled;

  const notionPopupWindow = "app-link-notion";
  const onPopupMessage = (event) => {
    if (event.origin !== process.env.GATSBY_APP_BASE_URL) {
      return;
    }
    const { data: params, source } = event;
    if (source && source?.name == notionPopupWindow) {
      //encodeURI
      const error = hasQueryError({
        params,
      });

      if (!!error) {
        failure(error);
        return;
      }
      refresh().then(() => redirect({ params }));
    }
  };

  return (
    <SettingsFormList
      settings={[
        {
          title: "Notion",
          icon: <NotionIcon boxSize={5} mr={2} />,
          description: `
          Enable and configure ${`"${
            info?.integrations?.notion?.origin || "Notion"
          }"`} workspace integration
          ${
            info?.integrations?.notion?.configuredBy
              ? `done by "${info?.integrations?.notion?.configuredBy}"`
              : ""
          }
          `,
          origin: info?.integrations?.notion?.origin,
          configuredBy: info?.integrations?.notion?.configuredBy,
          extended: defaultForm == "notion",
          form: notionEnabled ? (
            <EditNotionServiceForm
              onSubmit={(inputs: any) => {
                notionMutation.mutateAsync(inputs).catch((err) => {});
              }}
            />
          ) : (
            <LinkNotionServiceForm
              embedded
              // redirectURL={`${
              //   process.env.GATSBY_API_URL
              // }/auth/notion/authorize?tid=${
              //   current.tenant.id
              // }&p=${encodeURIComponent("settings?tab=settings.notion")}`}

              onLink={() =>
                openPopupWindow(
                  `${process.env.GATSBY_API_URL}/auth/notion/authorize?tid=${
                    current.tenant.id
                  }&p=${encodeURIComponent("settings?tab=settings.notion")}`,
                  notionPopupWindow,
                  onPopupMessage
                )
              }
            />
          ),
          status: (
            <Tag
              colorScheme={notionEnabled ? "green" : "gray"}
              variant={notionEnabled ? "solid" : "outline"}
              minW="65px"
              mx={0}
            >
              {notionEnabled ? "enabled" : "disabled"}
            </Tag>
          ),
        },
      ]}
    />
  );
};
