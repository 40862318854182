import React from "react";

import { navigate } from "gatsby";
import { BMessage, NotFoundIcon } from "@app/ui";

export const NotFound = ({ default: boolean }) => {
  return (
    <>
      <BMessage
        message={"Page not found"}
        icon={
          <>
            <NotFoundIcon boxSize={28} />
          </>
        }
      />
    </>
  );
};
