import { BMessage } from "@app/ui";
import { useSmallScreen } from "@app/ui/hooks";
import { HBox, VBox } from "@app/ui/Layout";
import { Asset, AssetFactory } from "@app/ui/Link/assets";
import { Stat, StatLabel, StatNumber, Tooltip } from "@app/ui/Stats";
import { DashBlock } from "@app/ui/Workspace/dashboard";
import { describeDuration, formatDuration } from "@app/utils";
import React from "react";

export const LinkStats = ({ avg, info, visitCount }) => {
  const { isSmallScreen } = useSmallScreen();
  return (
    <VBox width={"100%"}>
      {/* <DashBlock height={chartData.length ? "450px" : "200px"}> */}
      <DashBlock height={"110px"}>
        {/* <DashBlockHeading>Global stats </DashBlockHeading> */}
        <HBox width={"100%"} px={3} justify={"center"}>
          <Stat display={"flex"}>
            <StatLabel>Visits</StatLabel>
            <StatNumber>{visitCount == 0 ? "?" : visitCount}</StatNumber>
          </Stat>
          <Stat display={"flex"}>
            <StatLabel>Average visit duration</StatLabel>
            <StatNumber>
              <Tooltip
                label={
                  avg.visitDurationTotal == 0
                    ? "current duration may be less than 1 minute"
                    : `Duration: ${describeDuration(avg.visitDurationTotal)}`
                }
                fontSize="sm"
                placement="top"
              >
                {avg.visitDurationTotal == 0
                  ? "?"
                  : isSmallScreen
                  ? formatDuration(avg.visitDurationTotal)
                  : describeDuration(avg.visitDurationTotal)}
              </Tooltip>
            </StatNumber>
          </Stat>
        </HBox>
        {/* {chartData.length ? (
          <Box overflowX={"auto"} pt={3} padding={3}>
            <Stat>
              <StatLabel mr={"auto"} mb={3}>
                Average visit duration per page
              </StatLabel>
              <DefaultBarChart
                data={chartData}
                dataKey={{ keyX: "name", keyY: "duration" }}
                tickYFormatter={(label) => formatDuration(parseInt(label))}
                tooltip={
                  <DefaultTooltip
                    mapper={(payload, label) => ({
                      keyX: null,
                      x: label,
                      keyY: payload?.name,
                      y: describeDuration(parseInt(payload?.value)),
                    })}
                  />
                }
              />
            </Stat>
          </Box>
        ) : null} */}
      </DashBlock>
    </VBox>
  );
};

const prepareAssets = ({ contentActivity, contents }) => {
  let assets = Object.values(contentActivity).map((a: any) => {
    const content = contents[a.id];

    if (!content) {
      return null;
    }

    let asset = {
      id: a.id,
      title: content.title,
      type: content.type,
      properties: {},
    };

    switch (content.type) {
      case "embedded_pdf":
        asset.properties = {
          spentDuration: a.visitDurationTotal,
          pageCount: content.pageCount ?? 0,
          // visitedPages: 7,
          pageVisitDuration: a.visitPageDurationTotal,
        };
        break;
      case "video":
        asset.properties = {
          duration: content.totalDuration,
          spentDuration: a.visitDurationTotal || 0,
        };
        break;
      case "file":
        asset.properties = {
          lastDownloadAt: a.lastVisitAt || 0,
        };
        break;
      case "link":
        asset.properties = {
          lastVisitAt: a.lastVisitAt || 0,
        };
        break;
      case "detail":
        asset.properties = {
          lastVisitAt: a.lastVisitAt || 0,
        };
        break;
    }

    return asset;
  });

  return assets;
};

export const VisitorStats = ({ contentActivity, contents }) => {
  const assets = prepareAssets({ contentActivity, contents });
  return assets.length ? (
    <VBox width={"100%"} minH={"200px"} maxH={"450px"} overflowX={"auto"}>
      {assets
        .filter((a) => !!a)
        .map((a) => (
          <AssetFactory asset={a as Asset} key={a.id} />
        ))}
    </VBox>
  ) : (
    <BMessage message={"Content activities not available yet"} icon={null} />
  );
};
