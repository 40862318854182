import React from "react";

import { useSession } from "@app/context/Session";
import {
  useContactGroupList,
  useCreateLink,
  useDocument,
  useEditLinkSettings,
  useEnableLink,
  useLinkInfo,
  useSaveContactGroup,
  useWorkspaceInfo,
} from "@app/queries";
import {
  ActionMenu,
  ArrowBackIcon,
  B,
  BB,
  BButton,
  BH,
  BMessage,
  ErrorIcon,
  Header,
  LoaderIcon,
  StatsIcon,
  SubHeader,
  Tag,
  useToast,
} from "@app/ui";
import { EditLinkForm } from "@app/ui/Form";
import { LinkURL } from "@app/ui/Link/list";
import {
  getCustomLinkURL,
  getLinkAnalyticsURL,
  getLinkEditURL,
  getLinkURL,
} from "@app/utils";
import { backToDoc } from "@app/utils/directory";
import { navigate } from "gatsby-link";

export const CreateLink = ({ directoryId, documentId }) => {
  const {
    current: { tenant },
  } = useSession();
  const { success, failure } = useToast();

  const {
    isLoading: isTargetLoading,
    error: targetError,
    data: target,
  } = useContactGroupList(tenant.id);

  const targetMutation = useSaveContactGroup(
    tenant.id,
    () => success("Destination created"),
    () => failure("Create destination failed")
  );

  const {
    isLoading: isDocumentLoading,
    error: documentError,
    data: document,
  } = useDocument(tenant.id, directoryId, documentId);

  const linkMutation = useCreateLink(
    tenant.id,
    directoryId,
    documentId,
    (data) => {
      success("Link created");
      setTimeout(() => {
        navigate(
          getLinkEditURL({
            tenantId: tenant.id,
            dirId: directoryId,
            docId: documentId,
            linkId: data.id,
          })
        );
        // backToDoc(tenant.id, directoryId, documentId)
      }, 1000);
    },
    () => failure("Create link failed")
  );

  if (targetError)
    return (
      <BMessage
        message={"An error has occurred, can't load destination settings"}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  if (documentError)
    return (
      <BMessage
        message={"An error has occurred, can't load document settings"}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  return isTargetLoading || isDocumentLoading ? (
    <BMessage
      message={"Load link settings"}
      icon={<LoaderIcon boxSize={16} mt={24} />}
    />
  ) : (
    <>
      <Header
        title={document.title}
        icon={<ArrowBackIcon marginRight={1} boxSize={5} />}
        _onActionClick={() => {
          backToDoc(tenant.id, directoryId, documentId);
        }}
      />
      <SubHeader>Create a new link</SubHeader>
      <EditLinkForm
        versions={document.versions}
        loadTargets={async (key) => {
          return target.list
            .filter(
              (t) =>
                !key.trim() || t.name.toLowerCase().includes(key.toLowerCase())
            )
            .map((t) => ({ label: t.label, value: t.code }));

          // return new Promise((resolve, reject) => {
          //   setTimeout(() => {
          //     resolve([
          //       {
          //         label: "Dynamic sales",
          //         value: "dynamic-sales",
          //       },
          //     ]);
          //   }, 3000);
          // });
        }}
        onCreateTarget={async (key) => {
          try {
            const { data } = await targetMutation.mutateAsync({
              tenantId: tenant.id,
              name: key.trim(),
            });

            const { code, label } = data;

            return { value: code, label };
          } catch (err) {
            console.error(err);
          }
        }}
        onSubmit={async (inputs: any) => {
          const linkTarget = target.list.filter(
            (t) => inputs.target.trim() === t.code.trim()
          )?.[0];
          if (!linkTarget) {
            // throw new Error("Resolve destination error has occurred");
            failure("Resolve destination error has occurred");
          }

          try {
            await linkMutation.mutateAsync({
              ref: {
                id: document.id,
                externalId: document.externalId,
                title: document.title,
                type: document.type,
              },
              ...inputs,
              target: linkTarget.label,
              targetCode: linkTarget.code,
            });
          } catch (err) {
            // failure("an er")
          }
          // linkMutation
          //   .mutateAsync({
          //     ref: {
          //       id: document.id,
          //       externalId: document.externalId,
          //       title: document.title,
          //       type: document.type,
          //     },
          //     ...inputs,
          //     target: linkTarget.label,
          //     targetCode: linkTarget.code,
          //   })
          //   .catch((err) => {});
        }}
        onCancel={() => backToDoc(tenant.id, directoryId, documentId)}
      />
    </>
  );
};

export const EditLinkSettings = ({ directoryId, documentId, linkId }) => {
  const {
    current: { tenant },
  } = useSession();

  const { success, failure } = useToast();

  const { isLoading, error, data: link } = useLinkInfo(tenant.id, linkId);

  const linkMutation = useEditLinkSettings(
    tenant.id,
    directoryId,
    documentId,
    linkId,
    () => {
      success("Link settings updated");
      setTimeout(() => backToDoc(tenant.id, directoryId, documentId), 3000);
    },
    () => failure("Edit link settings failed")
  );

  const enableLinkMutation = useEnableLink(
    tenant.id,
    (data) => {
      success(`Link ${data.status}`);
    },
    () => failure("Change link enablement failed, please retry again")
  );

  const {
    query: {
      isLoading: isLoadingWorkspaceInfo,
      error: workspaceInfoError,
      data: workspaceInfo,
    },
  } = useWorkspaceInfo(tenant.id);

  if (error || workspaceInfoError)
    return (
      <BMessage
        message={"An error has occurred, can't load link settings"}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  return isLoading || isLoadingWorkspaceInfo ? (
    <BMessage
      message={"Load link settings"}
      icon={<LoaderIcon boxSize={16} mt={24} />}
    />
  ) : (
    <>
      <Header
        title={link?.refTitle ?? "Untitled link"}
        icon={<ArrowBackIcon marginRight={1} boxSize={5} />}
        _onActionClick={() => {
          backToDoc(tenant.id, directoryId, documentId);
        }}
      />
      <SubHeader>
        Edit link{" "}
        {link.target ? (
          <Tag description={`Destinated to "${link.target}"`}>
            {link.target}{" "}
          </Tag>
        ) : null}
        <Tag
          description={`Link status "${link.status}"`}
          variant={link.status == "enabled" ? "solid" : "outline"}
        >
          {link.status}{" "}
        </Tag>
        <ActionMenu
          marginX={0}
          actions={[
            {
              label: `${link.status == "enabled" ? "Disable" : "Enable"} link`,
              onClick: () => {
                enableLinkMutation
                  .mutateAsync({
                    linkId,
                    action: link.status == "enabled" ? "disable" : "enable",
                  })
                  .catch((err) => {});
              },
            },
          ]}
          children
        />
      </SubHeader>

      <B>
        <BH title={"Link url"}>
          <BButton
            onClick={() => {
              navigate(
                getLinkAnalyticsURL({
                  tenantId: tenant.id,
                  linkId: link.id,
                  dirId: directoryId,
                  docId: documentId,
                })
              );
            }}
            text={"Analytics"}
            shortText={""}
            icon={<StatsIcon boxSize={4} />}
          />
        </BH>
        <BB px={[15, 2, 10, 20]} w="fit-content">
          <LinkURL
            url={
              //   getLinkURL({
              //   type: "notionPage", // get link type from backend
              //   tenantId: tenant.id,
              //   linkId: link.id,
              // })

              workspaceInfo?.profile?.subDomains?.[0]
                ? getCustomLinkURL({
                    tenantSubDomain: workspaceInfo?.profile?.subDomains?.[0],
                    linkId: link.id,
                  })
                : getLinkURL({
                    type: "notionPage",
                    tenantId: tenant.id,
                    linkId: link.id,
                  })
            }
          />
        </BB>
      </B>
      <B>
        <BH title="Link settings" children />
        <BB>
          <EditLinkForm
            versions={link.versions}
            // target={link.target}
            onSubmit={async (inputs: any) => {
              try {
                await linkMutation.mutateAsync({
                  ...inputs,
                });
              } catch (err) {}
              // linkMutation
              //   .mutateAsync({
              //     ...inputs,
              //   })
              //   .catch((err) => {});
            }}
            onCancel={() => backToDoc(tenant.id, directoryId, documentId)}
            options={link.options}
            version={link.version}
            passcode={link.passcode}
          />
        </BB>
      </B>
    </>
  );
};
