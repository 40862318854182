import React, { useState } from "react";

import { Avatar } from "@app/ui/Avatar";
import { MiniButton } from "@app/ui/Button";
import { HBox, Text, VBox } from "@app/ui/Layout";
import { ListItem } from "@app/ui/List";

import { ChevronDownIcon, ChevronUpIcon } from "@app/ui/Icon";

import { useSmallScreen } from "@app/ui/hooks";
import { VisitorStats } from "@app/ui/Link/stats";
import { Time, Tooltip } from "@app/ui/Text";
import { describeDuration, formatDuration } from "@app/utils";

export const prepareBarChartdata = (pageDuration) => {
  let chartData = [];
  for (const p in pageDuration) {
    chartData.push({
      name: `Page ${p}`,
      duration: pageDuration[p],
    });
  }

  return chartData;
};

interface LinkVisitorItemProps {
  visitor: {
    name: string;
    visitDurationTotal: number;
    visitPageDurationTotal: any;
    lastVisitedAt: number;
    visitCount: number;
    contentActivity: any;
    email?: string;
    id?: string;
    type?: string;
  };

  contents: any[];
  // contacts: any;
  defaultExtend: boolean;
}

export const LinkVisitorItem = ({
  visitor,
  contents,
  // contacts = {},
  defaultExtend = false,
}: LinkVisitorItemProps) => {
  const [extend, setExtend] = useState(defaultExtend);

  // const [type, name] = visitor.name.split("#");
  // const label =
  //   type === "contact"
  //     ? contacts[name]?.email || `not-found-${name}@404`
  //     : type;

  // let chartData = prepareBarChartdata(visitor.visitPageDurationTotal);
  // chartData = [];

  const label = visitor?.email || visitor?.type || "anonymous";

  const { isSmallScreen } = useSmallScreen();

  return (
    <ListItem
      key={label}
      // onClick={() => setExtend(!extend)}
      paddingY={1}
      paddingX={2}
    >
      <VBox width={"100%"}>
        <HBox
          align={"center"}
          onClick={() => setExtend(!extend)}
          cursor={"pointer"}
        >
          <Avatar
            size={"xs"}
            name={label}
            src={null}
            title={label}
            borderRadius={4}
          />
          <VBox ml={2}>
            <Text>{label}</Text>
            <HBox>
              <Time at={visitor.lastVisitedAt} label={"Last seen"} />

              {visitor.visitCount > 1 ? (
                <Tooltip
                  label={`visited ${visitor.visitCount} times`}
                  fontSize="sm"
                  placement="top"
                >
                  <Text ml={1} mr={0} fontWeight={"medium"} fontSize="xs">
                    x{visitor.visitCount}
                  </Text>
                </Tooltip>
              ) : null}
            </HBox>
          </VBox>

          {visitor.visitDurationTotal ? (
            <Tooltip
              label={`Duration: ${describeDuration(
                visitor.visitDurationTotal
              )}`}
              fontSize="sm"
              placement="top"
            >
              <Text ml={"auto"} mr={1} fontWeight={"medium"}>
                {isSmallScreen
                  ? formatDuration(visitor.visitDurationTotal)
                  : describeDuration(visitor.visitDurationTotal)}
              </Text>
            </Tooltip>
          ) : (
            <Tooltip
              label={`Duration not available yet, it may be less than 1 minute.`}
              fontSize="sm"
              placement="top"
            >
              <Text ml={"auto"} mr={1} fontWeight={"medium"}>
                ?
              </Text>
            </Tooltip>
          )}

          <MiniButton
            aria-label={`"show more link visitor information`}
            onClick={() => setExtend(!extend)}
            icon={
              extend ? (
                <ChevronUpIcon boxSize={5} />
              ) : (
                <ChevronDownIcon boxSize={5} />
              )
            }
            mode={"ghost"}
            ml={2}
          />
        </HBox>
        {/* {extend && (
          <VBox p={3} pt={5} overflowY={"auto"}>
            <Text
              ml={1}
              mb={2}
              size={"sm"}
              textTransform={"uppercase"}
              fontWeight={"light"}
            >
              Statistics:
            </Text>
            {chartData.length ? (
              <DefaultBarChart
                data={chartData}
                dataKey={{ keyX: "name", keyY: "duration" }}
                tickYFormatter={(label) => formatDuration(parseInt(label))}
                tooltip={
                  <DefaultTooltip
                    mapper={(payload, label) => ({
                      keyX: null,
                      x: label,
                      keyY: payload?.name,
                      y: formatDuration(parseInt(payload?.value)),
                    })}
                  />
                }
              />
            ) : (
              <BMessage
                message={"Statistics per page are not available"}
                icon={null}
              />
            )}
          </VBox>
        )} */}
        {extend ? (
          <VBox pl={6} pt={3}>
            <Text
              ml={1}
              mb={2}
              size={"sm"}
              textTransform={"uppercase"}
              fontWeight={"light"}
            >
              Activities:
            </Text>
            <VisitorStats
              contentActivity={visitor.contentActivity}
              contents={contents}
            />
          </VBox>
        ) : null}
      </VBox>
    </ListItem>
  );
};
