import React, { useEffect } from "react";

import { useSession } from "@app/context/Session";
import { Header, SubHeader, useToast } from "@app/ui";
import { Select } from "@app/ui/Select";
import { navigate } from "@reach/router";

export const SelectWorkspace = ({ path }) => {
  const { current, tenants, switchTenant } = useSession();
  useEffect(() => {
    current && navigate(`/app/${current?.tenant?.id}`);
  }, [current?.tenant?.id]);

  const { failure, success } = useToast();

  return current ? null : (
    <>
      <Header title={`Select your workspace`} />
      <SubHeader></SubHeader>
      <Select
        options={tenants.map((role) => ({
          label: role.tenant.name,
          value: role.tenant.id,
        }))}
        placeholder={"Search a workspace by name"}
        onChange={(opt) => {
          switchTenant(opt.value)
            .then(() => {
              success("Workspace selected");
              navigate(`/app/${opt.value}/`);
            })
            .catch((err) => {
              failure("Select workspace failed. Please, refresh the page");
            });
        }}
      />
    </>
  );
};
