import React, { useEffect } from "react";

import { navigate, RouteComponentProps, Router } from "@reach/router";
// import { navigate } from "gatsby";
import { Redirect } from "@app/components/Auth/Callback";
import { Layout } from "@app/components/Layout";
import { Members } from "@app/components/Members";
import { Onboarding } from "@app/components/Onboarding";
import { PrivateRoute } from "@app/components/PrivateRoute";
import { Session } from "@app/context";
import { QueryClient, QueryClientProvider } from "react-query";

import { NotFound } from "@app/components/NotFound";
import {
  BButton,
  BMessage,
  Box,
  ErrorIcon,
  RepeatIcon,
  Spacer,
  useToast,
} from "@app/ui";

import {
  CreateDirectory,
  Directory,
  Document,
} from "@app/components/Directory";

import { Contacts } from "@app/components/Contact/List";
import { CreateLink, EditLinkSettings, Stats } from "@app/components/Link";
import { Home, SelectWorkspace, Settings } from "@app/components/Workspace";
import { useSession } from "@app/context/Session";

import { Link } from "@app/site";
import { ErrorBoundary } from "react-error-boundary";

export const Base = ({ children, path }) => {
  return <>{children}</>;
};

const Tenant = ({ children, tid = null, path }) => {
  const { current, switchTenant } = useSession();
  const { failure } = useToast();
  useEffect(() => {
    if (!!tid && ((current && current.tenant.id != tid) || !current)) {
      // if (!!tid && current && current.tenant.id != tid) {
      switchTenant(tid).catch((err) => {
        console.error(err);
        failure("invalid workspace, or access denied");
        navigate("/app/select-workspace");
      });
    }
  }, [current, tid]);
  // }, [tid]);

  return !current?.tenant?.id ? null : <>{children}</>;
};

const Landing = ({ navigate }: RouteComponentProps) => {
  const { current } = useSession();

  useEffect(() => {
    if (current) {
      navigate(`/app/${current.tenant.id}/`);
    } else {
      navigate(`/app/select-workspace`);
    }
  }, [current]);

  return null;
};

export interface AppProps {
  location: Location;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const App = ({ location }: AppProps) => {
  const { failure } = useToast();
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => {
        useEffect(() => {
          failure("Something went wrong...");
          console.error(error);
        }, []);

        return (
          <>
            <BMessage
              message={"Something went wrong..."}
              icon={
                <>
                  <ErrorIcon boxSize={28} />
                </>
              }
              action={
                <>
                  <Spacer h={36} />
                  <BButton
                    onClick={resetErrorBoundary}
                    text={"Try again"}
                    shortText={"Try again"}
                    icon={<RepeatIcon boxSize={6} ml={2} />}
                  />
                  or{" "}
                  <Link to={"/app"} onClick={resetErrorBoundary}>
                    Go back to home
                  </Link>
                </>
              }
            />
          </>
        );
      }}
      onError={(err) => {
        console.error(err);
      }}
    >
      <Session.Provider>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <Layout>
            <Router style={{ height: "inherit" }} component={Box}>
              <Redirect path="/app/auth/redirect" location={location} />
              <PrivateRoute path="/app/onboarding" component={Onboarding} />
              <PrivateRoute path="/app/" component={Landing} tenantRequired />
              <PrivateRoute
                path="/app/select-workspace"
                component={SelectWorkspace}
              />
              {/* <PrivateRoute
              path="/app/directory/:id"
              component={Directory}
              tenantRequired
            />
            <PrivateRoute
              path="/app/directory/create"
              component={CreateDirectory}
              tenantRequired
            />
            <PrivateRoute
              path="/app/directory/:directoryId/:documentId"
              component={Document}
              tenantRequired
            />
            <PrivateRoute
              path="/app/directory/:directoryId/:documentId/create"
              component={CreateLink}
              tenantRequired
            />
            <PrivateRoute
              path="/app/directory/:directoryId/:linkId/edit"
              component={EditLinkSettings}
              tenantRequired
            />
            <PrivateRoute
              path="/app/members"
              component={Members}
              tenantRequired
            /> */}
              <NotFound default />

              <Tenant path="/app/:tid">
                <PrivateRoute path="/" component={Home} tenantRequired />
                <PrivateRoute
                  path="/members"
                  component={Members}
                  tenantRequired
                />
                <PrivateRoute
                  path="/settings"
                  component={Settings}
                  tenantRequired
                />
                <PrivateRoute
                  path="/d/:id"
                  component={Directory}
                  tenantRequired
                />
                <PrivateRoute
                  path="/d/create"
                  component={CreateDirectory}
                  tenantRequired
                />
                <PrivateRoute
                  path="/d/:directoryId/:documentId"
                  component={Document}
                  tenantRequired
                />
                <PrivateRoute
                  path="/d/:directoryId/:documentId/l/create"
                  component={CreateLink}
                  tenantRequired
                />
                <PrivateRoute
                  path="/d/:directoryId/:documentId/l/:linkId/edit"
                  component={EditLinkSettings}
                  tenantRequired
                />
                <PrivateRoute
                  path="/d/:directoryId/:documentId/l/:linkId/analytics"
                  component={Stats}
                  tenantRequired
                />
                <PrivateRoute
                  path="/c/:group"
                  component={Contacts}
                  tenantRequired
                />
                <NotFound default />
              </Tenant>
            </Router>
          </Layout>
        </QueryClientProvider>
      </Session.Provider>
    </ErrorBoundary>
  );
};

export default App;
