import React, { ReactNode } from "react";

import {
  AlertDialog,
  AlertDialogBody, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay,
  Button
} from "@chakra-ui/react";

export interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  showCancel: boolean;
  cancelRef: React.Ref<any>;
  header?: ReactNode | string;
  body: ReactNode | string;
  action: { name: string; color: string; callback: () => void };
}

export const Dialog = ({
  isOpen,
  cancelRef,
  showCancel = true,
  onClose = () => {},
  header,
  body,
  action,
}) => {
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius={4}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" padding={3}>
              {header}
            </AlertDialogHeader>
            <AlertDialogBody padding={3}>{body}</AlertDialogBody>
            <AlertDialogFooter padding={3}>
              {showCancel && (
                <Button
                  ref={cancelRef}
                  onClick={onClose}
                  variant="outline"
                  rounded={"full"}
                >
                  Cancel
                </Button>
              )}
              <Button
                isDisabled={action.isDisabled}
                colorScheme={action.color}
                onClick={() => {
                  action.callback();
                  setTimeout(() => onClose(), 1000);
                }}
                ml={3}
                // variant="outline"
                // isRounded={true}
                rounded={"full"}
              >
                {action.name}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
