import React from "react";

import {
  SideMenuGroup,
  SideMenuItem,
  LoadingBlock,
  AddIcon,
  LoadingSideMenuGroup,
} from "@app/ui";
import { useSession } from "@app/context/Session";
import { useDirectoryList } from "@app/queries";
import { navigate, useLocation } from "@reach/router";
import { useEffect } from "react";

export const DirectoryList = () => {
  const { current } = useSession();

  useEffect(() => {
    if (!current?.tenant.id) {
      // navigate to a custom error page
      // in this page show error, and suggest some actions
      navigate("/app/error");
    }
  }, [current]);
  if (!current?.tenant.id) {
    return null;
  }

  const { isLoading, error, data: list } = useDirectoryList(current.tenant.id);

  const location = useLocation();
  if (error) {
    return <>{null}</>;
  }

  return isLoading ? (
    <LoadingSideMenuGroup title={"directories"} />
  ) : (
    <SideMenuGroup
      key="list_directories"
      title={"directories"}
      actionIcon={<AddIcon />}
      onClick={() => navigate(`/app/${current?.tenant?.id}/d/create`)}
    >
      {list
        .sort((a, b) => b.lastUpdatedAt - a.lastUpdatedAt)
        .map((d) => (
          <SideMenuItem
            key={d.id}
            title={d.name}
            to={`/app/${current.tenant.id}/d/${d.id}`}
            hasTooltip={true}
            current={location.pathname}
          />
        ))}
    </SideMenuGroup>
  );
};
