import { Avatar } from "@app/ui/Avatar";
import { Button } from "@app/ui/Button";
import { Dialog } from "@app/ui/Dialog";
import { FormControl, FormErrorMessage, Input } from "@app/ui/Form";
import { SettingsIcon } from "@app/ui/Icon";
import { Box, HBox, VBox, Wrap } from "@app/ui/Layout";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from "@app/ui/Menu";
import { Heading, Text } from "@app/ui/Text";
import React, { useRef, useState } from "react";

export type member = {
  id: string;
  name: string;
  email: string;
  avatarUrl?: string;
  role: "owner" | "admin" | "member" | "guest";
};

export type OnRoleChange = (
  memberId: string,
  oldRole: string,
  newRole: string
) => void;

interface MemberItemProps {
  member: member;
  onRoleChange: (memberId: string, oldRole: string, newRole: string) => void;
  onDeleteMember: (memberId: string) => void;
}

const MemberItem = ({
  member,
  onRoleChange,
  onDeleteMember,
}: MemberItemProps) => {
  const [isDialogOpen, setOpenDialog] = useState(false);
  const dialogRef = useRef();
  const dialog = (
    <Dialog
      isOpen={isDialogOpen}
      onClose={() => setOpenDialog(false)}
      cancelRef={dialogRef}
      header="Delete member"
      body="Are you sure? You can't undo this action afterwards."
      action={{
        name: "Delete",
        color: "red",
        callback: () => onDeleteMember(member.id),
      }}
    />
  );

  return (
    <HBox
      border={"1px solid"}
      borderColor={"gray.200"}
      borderRadius={4}
      padding={1}
      as={"article"}
      key={member.id}
    >
      <Avatar
        size={"lg"}
        name={member.name}
        src={member.avatarUrl}
        title={member.name}
        borderRadius={4}
      />
      <VBox paddingX={2} height={"65px"} width={"170px"}>
        <Text fontSize={"sm"} size={"xs"} noOfLines={1} >
          {member.name}
        </Text>
        <Text fontSize={"xs"} color={"gray.500"} noOfLines={1} >
          {member.email}
        </Text>
        <HBox justify={"flex"} marginTop={"auto"} height={"fit-content"}>
          <Box as="span" marginRight={"auto"} paddingTop={1}>
            <Text fontSize={"xs"} color={"gray.500"} noOfLines={1} >
              {member.role}
            </Text>
          </Box>
          <Menu closeOnSelect={true} isLazy={true}>
            <MenuButton aria-label="Options" variant="unstyled">
              <SettingsIcon boxSize={3} marginLeft={"auto"} />
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                defaultValue={member.role}
                title="Change Role:"
                type="radio"
                onChange={(newRole: string) =>
                  onRoleChange(member.id, member.role, newRole)
                }
              >
                <MenuItemOption value="member">Member</MenuItemOption>
                <MenuItemOption value="admin">Administrator</MenuItemOption>
              </MenuOptionGroup>
              <MenuDivider />
              <MenuItem onClick={() => setOpenDialog(true)}>
                Delete member
              </MenuItem>
            </MenuList>
          </Menu>
        </HBox>
      </VBox>
      {dialog}
    </HBox>
  );
};

export interface MemberGroupProps {
  title?: string;
  members: member[];
  onRoleChange: OnRoleChange;
  onDeleteMember: (memberId: string) => void;
}

export const MemberGroup = ({
  title,
  members,
  onRoleChange,
  onDeleteMember,
}: MemberGroupProps) => {
  return (
    <>
      {/* <Select
        options={[
          { value: "chocolate", label: "chocolate" },
          { value: "strawberry", label: "Strawberry" },
          { value: "vanilla", label: "Vanilla" },
        ]}
      /> */}
      {title && (
        <Heading as="h4" size="sm" fontWeight="400" paddingY={3}>
          {title}:
        </Heading>
      )}
      <Wrap
        as="section"
        spacing={{ base: "7px", xl: "30px", md: "10px", sm: "7px" }}
      >
        {members.map((m) => (
          <MemberItem
            member={m}
            onRoleChange={onRoleChange}
            onDeleteMember={onDeleteMember}
            key={m.id}
          />
        ))}
      </Wrap>
    </>
  );
};

interface AddMemberProps {
  onAddMember: (email: string) => void;
  existingMembers: string[];
}
export const AddMember = ({ onAddMember, existingMembers }: AddMemberProps) => {
  const [isDialogOpen, setOpenDialog] = React.useState(false);
  const dialogRef = React.useRef();

  const [email, setEmail] = React.useState(null);

  const dialog = (
    <Dialog
      isOpen={isDialogOpen}
      onClose={() => setOpenDialog(false)}
      cancelRef={dialogRef}
      showCancel={false}
      header="Add member"
      body={
        // <SelectUser
        //   placeholder={"add by username, full name, or email"}
        //   options={[
        //     { value: "chocolate", label: "chocolate" },
        //     { value: "strawberry", label: "Strawberry" },
        //     { value: "vanilla", label: "Vanilla" },
        //   ]}
        // />
        <FormControl id="add_member" isInvalid={!email}>
          <Input
            type="email"
            placeholder={"Ex: martin.demind@gmail.com"}
            onChange={(e) => {
              const email = String(e.target.value).toLowerCase();

              const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (regex.test(email) && !existingMembers.includes(email)) {
                setEmail(email);
              } else {
                setEmail(null);
              }
            }}
          />
          {!email && (
            <FormErrorMessage>
              Invalid email, or already exists
            </FormErrorMessage>
          )}
        </FormControl>
      }
      action={{
        isDisabled: !email,
        name: "Invite",
        color: "blue.400",
        callback: () => onAddMember(email),
      }}
    />
  );
  return (
    <>
      <Button
        variant="outline"
        borderRadius={4}
        onClick={() => setOpenDialog(true)}
      >
        Add Member
      </Button>
      {dialog}
    </>
  );
};
