import React, { useEffect, useState } from "react";

import {
  ActionMenu,
  B,
  BB,
  BButton,
  BH,
  BMessage,
  ErrorIcon,
  Header,
  LoaderIcon,
  LoadingPage2,
  RepeatIcon,
  SearchIcon,
  SubHeader,
  Tag,
  Time,
  useToast,
} from "@app/ui";
import { DocumentItem } from "@app/ui/Directory";

import { List } from "@app/ui/List";

import { useSession } from "@app/context/Session";
import {
  useDirectory,
  useFetchNotionDocument,
  useSynchronizeNotionDirectory,
} from "@app/queries";
import { Spacer } from "@app/ui";
import { SearchInput } from "@app/ui/Form";
import { isBrowser } from "@app/utils";
import { directoryStatus, shortId } from "@app/utils/directory";
import { navigate } from "gatsby";
import debounce from "lodash.debounce";

interface DirectoryProps {
  id: string;
}

export const Directory = ({ id: directoryId }: DirectoryProps) => {
  const {
    current: { tenant },
  } = useSession();

  const { query, refresh } = useDirectory(tenant.id, directoryId);

  const { isLoading, error, data: directory } = query;

  const [search, setSearch] = useState(null);
  const onSearch = debounce((key) => {
    setSearch(key.toLowerCase());
  }, 300);

  useEffect(() => {
    if (directory && !directory.profile) {
      refresh();
    }
  });
  const { success, failure } = useToast();

  const syncMutation = useSynchronizeNotionDirectory(
    tenant.id,
    directoryId,
    ({ refresh, ...data }) => {
      if (["populated", "synchronized"].includes(data.status)) {
        if (data.currentLastUpdatedAt < data.lastUpdatedAt) {
          refresh().then(() => {
            success("Directory synchronized with origin.");
          });
        } else {
          success("Directory already synchronized, no updates found.");
        }
      } else if (
        ["populateFailed", "synchronizeFailed"].includes(data.status)
      ) {
        // refresh()
        failure("Synchronize directory with origin failed.");
      }
    },
    () => failure("An error has occured during. please try again.")
  );

  const fetchMutation = useFetchNotionDocument(
    tenant.id,
    (data) => {
      if (
        directory.list
          .find((d) => d.id == data.refId)
          ?.versions.includes(data.refVersion)
      ) {
        success("The latest available version is already captured");
      } else {
        success("New version is captured");
      }
    },
    () => failure("Capture latest version failed, please retry again")
  );

  const [isSyncingDirectory, setIsSyncingDirectory] = useState(false);

  if (error)
    return (
      <BMessage
        message={"An error has occurred, can't load directory."}
        icon={<ErrorIcon boxSize={24} />}
      />
    );

  if (isLoading) return <LoadingPage2 />;

  const { list, status, profile, lastUpdatedAt } = directory;

  const flist = list.filter(
    (d) => !search || d.title.toLowerCase().includes(search)
  );

  return (
    <>
      <Header title={profile?.name} />
      <SubHeader>
        <Time
          at={lastUpdatedAt}
          label={lastUpdatedAt ? "Last updated" : "Not yet updated"}
          size={"sm"}
        />
        {status && (
          <Tag description={`Directory is ${directoryStatus(status)}`}>
            {directoryStatus(status)}{" "}
          </Tag>
        )}

        <ActionMenu
          marginX={2}
          actions={[
            {
              label: "Open in Notion",
              onClick: () => {
                isBrowser() &&
                  !!profile?.externalId &&
                  window
                    .open(
                      `https://www.notion.so/${profile?.externalId.replace(
                        /-/g,
                        ""
                      )}`,
                      "_blank"
                    )
                    .focus();
              },
            },
            // {
            //   label: "Delete directory",
            //   onClick: () => {},
            // },
          ]}
          children
        />
      </SubHeader>
      <B>
        {!["created", "linked", "linkFailed", "populateFailed"].includes(
          status
        ) ? (
          <>
            <BH title={""}>
              {/* <MiniButton
                aria-label={`synchronise directory references`}
                icon={<RepeatIcon boxsize={6} />}
                mode={"outline"}
                ml={0}
                onClick={async () => {
                  await syncMutation.mutateAsync({ lastUpdatedAt });
                }}
              /> */}
              <BButton
                onClick={() => {
                  setIsSyncingDirectory(true);
                  syncMutation
                    .mutateAsync({ lastUpdatedAt })
                    .finally(() => {
                      setIsSyncingDirectory(false);
                    })
                    .catch((err) => {});
                }}
                text={""}
                shortText={""}
                description={"synchronize with origin"}
                icon={<RepeatIcon boxsize={6} />}
                isLoading={isSyncingDirectory}
                // primary
              />
              <SearchInput
                onChange={onSearch}
                placeholder={"Search by document title"}
              />
            </BH>
            <BB>
              {(list || []).length ? (
                <List mt={0}>
                  {flist.length ? (
                    flist.map((d, i) => (
                      <DocumentItem
                        key={i}
                        document={d}
                        onClick={() =>
                          navigate(
                            `/app/${tenant.id}/d/${directoryId}/${shortId(
                              d.id
                            )}`
                          )
                        }
                        onCaptureVersion={
                          Math.max(...d.versions) < d.lastUpdatedAt
                            ? () => {
                                return fetchMutation.mutateAsync({
                                  refId: d.id,
                                  refType: d.type,
                                });
                              }
                            : null
                        }
                      />
                    ))
                  ) : (
                    <BMessage
                      message={
                        "Directory empty list, change search key word, or resynchronize with origin."
                      }
                      icon={<SearchIcon boxSize={16} mb={10} />}
                    />
                  )}
                </List>
              ) : (
                <BMessage
                  message={"No document found."}
                  icon={<SearchIcon boxSize={16} mb={10} />}
                />
              )}
            </BB>
          </>
        ) : (
          <>
            <BMessage
              message={"Directory is not yet populated from origin."}
              icon={
                <>
                  <LoaderIcon boxSize={16} mb={3} />
                  {/* <LoaderIcon boxSize={16} ml={2} mb={3} /> */}
                </>
              }
              action={
                <>
                  <Spacer h={36} />
                  <BButton
                    onClick={() => {
                      setIsSyncingDirectory(true);
                      syncMutation
                        .mutateAsync({ lastUpdatedAt })
                        .finally(() => {
                          setIsSyncingDirectory(false);
                        })
                        .catch((err) => {});
                    }}
                    text={"synchronize"}
                    shortText={"synchronize"}
                    // description={"synchronize with origin"}
                    icon={<RepeatIcon boxSize={6} ml={2} />}
                    isLoading={isSyncingDirectory}
                    // primary
                  />
                </>
              }
            />
          </>
        )}
      </B>
    </>
  );
};
