import React from "react";

import {
  SideMenuGroup,
  SideMenuItem,
  LoadingBlock,
  AddIcon,
  ArrowForwardIcon,
  LoadingSideMenuGroup,
} from "@app/ui";
import { useSession } from "@app/context/Session";
import { useContactGroupList } from "@app/queries";
import { navigate, useLocation } from "@reach/router";
import { useEffect } from "react";

export const ContactGroupList = () => {
  const { current } = useSession();

  useEffect(() => {
    if (!current?.tenant.id) {
      navigate("/app/error");
    }
  }, [current]);
  if (!current?.tenant.id) {
    return null;
  }

  const { isLoading, error, data } = useContactGroupList(current.tenant.id);

  const location = useLocation();
  if (error) {
    return <>{null}</>;
  }

  return isLoading ? (
    <LoadingSideMenuGroup title={"contacts"} />
  ) : (
    <SideMenuGroup
      key="list_contacts"
      title={"contacts"}
      // actionIcon={<ArrowForwardIcon boxSize={4} />}
      // onClick={() => navigate(`/app/${current.tenant.id}/c/_all`)}
    >
      <SideMenuItem
        key={"#all"}
        title={"# ALL"}
        to={`/app/${current.tenant.id}/c/${"_all"}`}
        current={location.pathname}
      />
      {data.list
        .filter((g) => g.active)
        .map((g) => (
          <SideMenuItem
            key={g.code}
            title={g.label}
            to={`/app/${current.tenant.id}/c/${g.code}`}
            hasTooltip={true}
            current={location.pathname}
          />
        ))}
    </SideMenuGroup>
  );
};
