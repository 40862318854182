import React, { useState } from "react";

import {
  ArrowBackIcon,
  B,
  BB,
  BButton,
  BH,
  BMessage,
  ErrorIcon,
  HBox,
  Header,
  InfoOutlineIcon,
  LinkIcon,
  LoadingPage1,
  SearchIcon,
  SubHeader,
  Tag,
  Text,
  Time,
  TimeIcon,
  useToast,
  Version,
} from "@app/ui";
import { useSmallScreen } from "@app/ui/hooks";
import { LinkItem } from "@app/ui/Link/list";

import { useSession } from "@app/context/Session";
import {
  useDocument,
  useEnableLink,
  useFetchNotionDocument,
  useWorkspaceInfo,
} from "@app/queries";

import { documentStatus, shortId } from "@app/utils/directory";

import { getCustomLinkURL, getLinkURL } from "@app/utils";
import { Switch } from "@chakra-ui/switch";
import { RouteComponentProps } from "@reach/router";

interface DocumentProps extends RouteComponentProps {
  directoryId: string;
  documentId: string;
}

export const Document = ({
  directoryId,
  documentId,
  navigate,
}: DocumentProps) => {
  const {
    current: { tenant },
  } = useSession();

  const { success, failure } = useToast();

  const { isSmallScreen } = useSmallScreen();

  const {
    isLoading,
    error,
    data: document,
  } = useDocument(tenant.id, directoryId, documentId);

  const fetchDocumentMutation = useFetchNotionDocument(
    tenant.id,
    (data) => {
      if (document.versions.includes(data.refVersion)) {
        success("The latest available version is already captured");
      } else {
        success("New version is captured");
      }
    },
    () => failure("Capture latest version failed, please retry again")
  );

  const enableLinkMutation = useEnableLink(
    tenant.id,
    (data) => {
      success(`Link ${data.status}`);
    },
    () => failure("Change link enablement failed, please retry again")
  );

  const [isLoadingVersion, setIslaodingVersion] = useState(false);
  const [displayLinksAll, setDisplayLinksAll] = useState(false);

  const {
    query: {
      isLoading: isLoadingWorkspaceInfo,
      error: workspaceInfoError,
      data: workspaceInfo,
    },
  } = useWorkspaceInfo(tenant.id);

  if (error || workspaceInfoError)
    <BMessage
      message={"An error has occurred, can't load document."}
      icon={<ErrorIcon boxSize={24} />}
    />;

  if (isLoading || isLoadingWorkspaceInfo) return <LoadingPage1 />;

  const hasVersion = (document.versions || []).length;

  return (
    <>
      <Header
        title={document.title}
        icon={<ArrowBackIcon marginRight={1} boxSize={5} />}
        _onActionClick={() => {
          navigate(`/app/${tenant.id}/d/${directoryId}`);
        }}
      />
      <SubHeader>
        <Time at={document.lastUpdatedAt} label={"Last updated"} size={"sm"} />
        {document.status ? (
          <Tag description={`Document is ${documentStatus(document.status)}`}>
            {documentStatus(document.status)}
          </Tag>
        ) : null}
      </SubHeader>

      <HBox>
        <B>
          <BH title={"Versions"}>
            <BButton
              onClick={() => {
                setIslaodingVersion(true);
                fetchDocumentMutation
                  .mutateAsync({
                    refId: document.id,
                    refType: document.type,
                  })
                  .catch((err) => {})
                  .finally(() => setIslaodingVersion(false));
              }}
              text={"Capture latest"}
              shortText={"Capture"}
              icon={<TimeIcon boxSize={4} />}
              isLoading={isLoadingVersion}
            />
          </BH>
          <BB
            maxHeight={"200px"}
            overflowY={"auto"}
            width={!isSmallScreen && hasVersion ? "fit-content" : "full"}
          >
            {hasVersion ? (
              document.versions
                .sort()
                .reverse()
                .map((v, i) => <Version key={i} version={v} />)
            ) : (
              <BMessage
                message={"Capture first a version of the document"}
                icon={<InfoOutlineIcon boxSize={16} mb={8} />}
              />
            )}
          </BB>
        </B>
      </HBox>

      {hasVersion ? (
        <HBox>
          <B>
            <BH title={"Related Links"}>
              {document.links.find((l) => l.status === "disabled") ? (
                <HBox
                  ml={"auto"}
                  bg="blackAlpha.50"
                  h={7}
                  rounded={"full"}
                  px={3}
                  // borderWidth={1}
                  // borderColor={"gray.400"}
                >
                  {" "}
                  <Text fontWeight="semibold" fontSize="sm" color="gray.600">
                    Show all
                  </Text>
                  <Switch
                    colorScheme="gray"
                    ml={1}
                    onChange={() => {
                      setDisplayLinksAll(!displayLinksAll);
                    }}
                    onClick={() => {
                      setDisplayLinksAll(!displayLinksAll);
                    }}
                    checked={displayLinksAll}
                    size="sm"
                  />
                </HBox>
              ) : null}
              {/* <ActionMenu
                marginX={1}
                icon={<PointsIcon mr={1} boxSize={7} viewBox={"-5 -2 24 24"} />}
                actions={[
                  {
                    label: "Display disabled links",
                    onClick: () => {
                      setDisplayDisabledLinks(true);
                    },
                  },
                ]}
                children
              /> */}
              <BButton
                onClick={() =>
                  navigate(
                    `/app/${tenant.id}/d/${directoryId}/${documentId}/l/create`
                  )
                }
                text={"Create a Link"}
                shortText={"Create"}
                icon={<LinkIcon boxSize={4} />}
                primary
              />
            </BH>
            <BB>
              {/* {displayLinksAll ? "ok" : "nok"}cccc */}
              {(document.links || []).length ? (
                (displayLinksAll
                  ? document.links
                  : document.links.filter((l) => {
                      return l.status === "enabled";
                    })
                ).map((l, i) => (
                  <LinkItem
                    // url={`${process.env.GATSBY_VIEWER_BASE_URL}/${
                    //   document.type == "notionPage" ? "n" : "doc"
                    // }/${tenant.id}.${l.id}`}
                    url={
                      workspaceInfo?.profile?.subDomains?.[0]
                        ? getCustomLinkURL({
                            tenantSubDomain:
                              workspaceInfo?.profile?.subDomains?.[0],
                            linkId: l.id,
                          })
                        : getLinkURL({
                            type: document.type,
                            tenantId: tenant.id,
                            linkId: l.id,
                          })
                    }
                    tenantId={tenant.id}
                    key={i}
                    link={{
                      ...l,
                      type: document.type,
                      target: l.targetLabel,
                      createdAt: l.createdAt,
                    }}
                    onClickSettings={() => {
                      navigate(
                        `/app/${tenant.id}/d/${directoryId}/${shortId(
                          document.id
                        )}/l/${l.id}/edit`
                      );
                    }}
                    onHandleEnbalement={({ linkId, action }) => {
                      enableLinkMutation
                        .mutateAsync({
                          linkId,
                          action,
                        })
                        .catch((err) => {});
                    }}
                    onClickStats={() => {
                      navigate(
                        `/app/${tenant.id}/d/${directoryId}/${shortId(
                          document.id
                        )}/l/${l.id}/analytics`
                      );
                    }}
                  />
                ))
              ) : (
                <BMessage
                  message={"No links were found. Create a new one."}
                  icon={<SearchIcon boxSize={16} mb={10} />}
                />
              )}
            </BB>
          </B>
        </HBox>
      ) : null}
    </>
  );
};
