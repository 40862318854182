import { HBox, Text, VBox } from "@app/ui/Layout";
import React, { useState } from "react";

import { MiniButton } from "@app/ui/Button";
import { ChevronRightIcon, NotionPageIcon, TimeIcon } from "@app/ui/Icon";
import { ListItem } from "@app/ui/List";
import { Tag, Time, Tooltip } from "@app/ui/Text";
import { documentStatus } from "@app/utils/directory";
import { useColorMode } from "@chakra-ui/color-mode";

type document = {
  id: string;
  type: string;
  title: string;
  lastUpdatedAt: number;
  status: string;
  versions: number[];
  visitCount: number;
};

interface DocumentItemProps {
  document: document;
  onClick?: () => void;
  onCaptureVersion?: () => Promise<void>;
}

export const DocumentItem = ({
  document,
  onClick,
  onCaptureVersion = null,
}: DocumentItemProps) => {
  const [isCapturing, setIsCapturing] = useState(false);

  const { colorMode } = useColorMode();

  return (
    <ListItem onClick={onClick} paddingY={2}>
      <Tooltip label={"Notion page"} fontSize="sm" placement="top">
        <Text>
          <NotionPageIcon boxSize={6} />
        </Text>
      </Tooltip>
      <VBox marginRight={"auto"} marginLeft={2}>
        <Text fontSize={"md"} fontWeight={"medium"} paddingBottom={1}>
          {document.title}
        </Text>
        <HBox align={"center"}>
          <Time at={document.lastUpdatedAt} label={"Updated"} />
          <Tag id={document.id}>{documentStatus(document.status)}</Tag>
        </HBox>
      </VBox>
      <VBox>
        {!!onCaptureVersion ? (
          <MiniButton
            aria-label={`visit ${document.title} document information`}
            onClick={(e) => {
              e.stopPropagation();

              setIsCapturing(true);
              onCaptureVersion()
                .catch((err) => {
                  console.error(err);
                })
                .finally(() => {
                  setIsCapturing(false);
                });
            }}
            icon={
              <Tooltip label={"capture last available version"} placement="top">
                <Text px={1} py={0}>
                  Capture <TimeIcon ml={0.5} boxSize={3.5} />
                </Text>
              </Tooltip>
            }
            mode={colorMode === "light" ? "outline" : "ghost"}
            colorScheme={"green"}
            bg={colorMode === "light" ? "green.50" : "green.500"}
            size="xs"
            isDisabled={isCapturing}
            isLoading={isCapturing}
            mb={0.5}
          />
        ) : null}
        <MiniButton
          aria-label={`visit ${document.title} document information`}
          onClick={onClick}
          icon={<ChevronRightIcon boxSize={5} />}
          mode={"ghost"}
        />
      </VBox>
    </ListItem>
  );
};
